import { Box, Text } from 'grommet'

const InfoTile = (props) => {
  const { icon, number, title } = props

  return (
    <Box direction="row" pad="small" justify="evenly" fill>
      {icon}
      <Box direction="column" gap="none">
        <Text size="xlarge" alignSelf="center" weight="number">
          {number}
        </Text>

        <Text alignSelf="center" size="small" weight="bold">
          {title}
        </Text>
      </Box>
    </Box>
  )
}

export default InfoTile

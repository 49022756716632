import React, { useState, useContext, useEffect } from 'react'
import {
  Box,
  Button as GRButton,
  InfiniteScroll,
  Text,
  Table,
  TableRow,
  TableBody,
  TableCell,
} from 'grommet'
import { Alert, SettingsOption, View } from 'grommet-icons'
import { DBContext } from '../../controller/contexts/dbContext'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import VideoIcon from '@material-ui/icons/SlowMotionVideo'
import LessonTable from '../components/Teach/lessonTable'
import LessonEntryEditDialogue from '../components/Teach/lessonUploadEditComponent'
import Backdrop from '@material-ui/core/Backdrop'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import LessonViewsPage from '../components/Teach/viewLessonViewsPage'
import VideoCommentsDialogue from '../components/Teach/videoCommentsDialogue'
import LessonCard from '../components/Teach/lessonCard'
import BatchSelector from '../components/batchSelector'
import ErrorStatusMessage from '../components/errorStatusMessage'
import { Paper } from '@material-ui/core'
import { DocumentVideo, DocumentPdf } from 'grommet-icons'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const TeachingPage = (props) => {
  const classes = useStyles()
  const {
    fetchCourseLessons,
    lessonsList,
    selectedCourse,
    courseBatches,
    selectedBatch,
    fetching,
  } = useContext(DBContext)
  const [loading, setLoading] = useState(false)
  const [openLessonEntryDialogue, setOpenLessonEntryDialogue] = useState(false)
  const [lessonToBeEdited, setLessonToBeEdited] = useState(null)
  const [studentCount, setStudentCount] = useState(0)

  // fetch courses lessons for every tyme batch changed
  useEffect(() => {
    if (selectedCourse && selectedBatch) {
      fettchInitialData()
    }
  }, [selectedBatch])

  const fettchInitialData = async () => {
    const error = await fetchCourseLessons()
    if (error) {
      return alert(error)
    }
  }

  // For Lesion Views Dialogue
  const [
    lessonForLessonViewsDialogue,
    setLessonForLessonViewsDialogue,
  ] = useState(null)
  const [isLessonViewsDialogueOpen, setIsLessonViewsDialogueOpen] = useState(
    false,
  )
  // For Lesson Comments Dialogue
  const [lessonForCommets, setLessonForCommets] = useState(null)
  const [
    isLessonCommentsDialogueOpen,
    setIsLessonCommentsDialogueOpen,
  ] = useState(false)

  // for creating new lesson
  const openLessonEntry = () => {
    setLessonToBeEdited(null)
    setOpenLessonEntryDialogue(true)
  }
  // For editing lesson
  const openEditDiaglogue = (lesson) => {
    setLessonToBeEdited(lesson)
    setOpenLessonEntryDialogue(true)
  }

  // For closing lesson entry
  const handleClose = () => {
    setOpenLessonEntryDialogue(false)
  }

  // const updateStudentCount = (count) => {
  //   console.log('count form edonlie', count)
  //   setStudentCount(count)
  // }

  // // LessoN VIEWS
  // // For Lesion Views Dialogue
  // const openLessonViewsDialogue = (lessonData) => {
  //   setLessonForLessonViewsDialogue(lessonData)
  //   setIsLessonViewsDialogueOpen(true)
  // }
  // // For closing of lesion views dialogue
  // const closeLessonViewsDialogue = () => {
  //   setLessonForLessonViewsDialogue(null)
  //   setIsLessonViewsDialogueOpen(false)
  // }

  // const returnLessonsViewDialogue = () => {
  //   return (
  //     <>
  //       <Dialog
  //         fullScreen
  //         TransitionComponent={Transition}
  //         aria-labelledby="customized-dialog-title-examView23829"
  //         open={isLessonViewsDialogueOpen}
  //       >
  //         <Toolbar>
  //           <IconButton
  //             color="inherit"
  //             edge="end"
  //             onClick={closeLessonViewsDialogue}
  //             aria-label="close"
  //           >
  //             <CloseIcon />
  //           </IconButton>
  //           <Typography>Close</Typography>
  //         </Toolbar>

  //         <DialogContent>
  //           <LessonViewsPage
  //             close={closeLessonViewsDialogue}
  //             lessonToView={lessonForLessonViewsDialogue}
  //           />
  //         </DialogContent>
  //       </Dialog>
  //     </>
  //   )
  // }
  // COMMENTS
  // For comments Views Dialogue
  const openLessonCommentsDialogue = (lessonData) => {
    setLessonForCommets(lessonData)
    setIsLessonCommentsDialogueOpen(true)
  }
  // For closing of lesion views dialogue
  const closeLessonCommentsDialogue = () => {
    setLessonForCommets(null)
    setIsLessonCommentsDialogueOpen(false)
  }

  const returnVideoCommentsDialogue = () => {
    return (
      <>
        <Dialog
          fullScreen
          TransitionComponent={Transition}
          aria-labelledby="customized-dialog-title-examView23829dss"
          open={isLessonCommentsDialogueOpen}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              edge="end"
              onClick={closeLessonCommentsDialogue}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography>Close</Typography>
          </Toolbar>

          <DialogContent>
            <VideoCommentsDialogue
              close={closeLessonCommentsDialogue}
              lessonData={lessonForCommets}
            />
          </DialogContent>
        </Dialog>
      </>
    )
  }
  // For loading more lessions on the end of scrolling
  //Infinity scrool method
  // this is for fetching new content
  // once the currsior deations
  const loadMoreLessions = () => {
    console.log('loading more lessons')
  }

  /**
   * For rendering lession list
   * We are using InfinityScorll component
   * to dinamically render the next items,
   * by calling loadmMoreLessions() function
   */
  const renderLessionsList = () => {
    // if lessions are not avaialble
    // this will render this message
    if (fetching) {
      return (
        <Box justify="center" align="center">
          <Text alignSelf="center">
            <CircularProgress />
          </Text>
        </Box>
      )
    }
    //return <Text>{lessonsList && lessonsList.length}</Text>
    if (!lessonsList || lessonsList.length < 1) {
      return (
        <Box justify="center" align="center">
          <Text alignSelf="center">
            No Data Available, Please create a new lession
          </Text>
        </Box>
      )
    }
    // if lessions data is available
    // it will show the list of lessions
    return (
      <Box overflow={{ vertical: 'scroll' }} width="600px">
        <Table>
          <TableBody>
            <InfiniteScroll
              items={[...lessonsList]}
              onMore={loadMoreLessions}
              {...props}
            >
              {(item) => (
                <TableRow>
                  <LessonCard
                    lesson={item}
                    openEdit={openEditDiaglogue}
                    openCommentsView={openLessonCommentsDialogue}
                  />
                </TableRow>
              )}
            </InfiniteScroll>
          </TableBody>
        </Table>
      </Box>
    )
  }
  //
  return (
    <Box margin={{ bottom: '0px', left: 'small' }} direction="column">
      {/* {isLessonViewsDialogueOpen && returnLessonsViewDialogue()} */}
      {isLessonCommentsDialogueOpen && returnVideoCommentsDialogue()}
      {/* {error && alert(error)} */}
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {openLessonEntryDialogue && (
        <LessonEntryEditDialogue
          open={openLessonEntryDialogue}
          closeDialogue={handleClose}
          lessonData={lessonToBeEdited}
        />
      )}
      <Box direction="row" justify="between">
        <Box width="small">
          <BatchSelector />
        </Box>

        <Box height="100px" pad="small">
          <GRButton
            alignSelf="end"
            label="+ Create New Lession"
            primary
            onClick={openLessonEntry}
            disabled={!selectedBatch}
          />
        </Box>
      </Box>

      <Box margin={{ bottom: '0px' }} overflow={{ vertical: 'scroll' }}>
        {/* For Showing error Messages if they are present */}
        {!selectedCourse && (
          <ErrorStatusMessage
            iconComponent={<Alert size="medium" color="status-warning" />}
            errorMessage={'Please Fill Course Details In Settings Page'}
          />
        )}
        {!courseBatches && (
          <ErrorStatusMessage
            iconComponent={<Alert size="medium" color="status-warning" />}
            errorMessage={'Please Create a Batch in Batches Page'}
          />
        )}
        {renderLessionsList()}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '8%',
    padding: theme.spacing(2),
    marginBottom: '2%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  padding: {
    padding: 12,
  },
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */,
    padding: 20,
    marginBottom: 8,
  },
  button: {
    margin: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  input: {
    display: 'none',
  },
}))

export default TeachingPage

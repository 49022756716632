export default class CourseStudent {
  constructor(id, studentProfileObject) {
    this.id = id
    this.firstName = studentProfileObject.firstName
    this.lastName = studentProfileObject.lastName
    this.displayName = `${studentProfileObject.firstName} ${studentProfileObject.lastName}`
    this.address = studentProfileObject.address
    this.pincode = studentProfileObject.pincode
    this.country = studentProfileObject.country
    this.state = studentProfileObject.state
    this.city = studentProfileObject.city
    this.district = studentProfileObject.district
    this.motherPhoneNumber = studentProfileObject.motherPhoneNumber
    this.fatherPhoneNumber = studentProfileObject.fatherPhoneNumber
    this.motherName = studentProfileObject.motherName
    this.fatherName = studentProfileObject.fatherName
    this.dob = studentProfileObject.dob
    this.imageUrl = studentProfileObject.imageUrl
    this.studentClass = studentProfileObject.studentClass
      ? studentProfileObject.studentClass
      : 'No data'
    // NEW: For course
    this.courseBatch = studentProfileObject.batchTitle
    this.courseBatchId = studentProfileObject.batchId
    this.appliedBatchTitle = studentProfileObject.appliedBatchTitle
    this.appliedBatchId = studentProfileObject.appliedBatchId
    this.appliedBatch = studentProfileObject.appliedBatch
    this.isRefferd = studentProfileObject.isRefferd
    this.referrerType = studentProfileObject.referrerType
    this.refferdBy = studentProfileObject.refferdBy
  }
  returnStudentProfileObject() {
    return {
      studentId: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      displayName: `${this.firstName} ${this.lastName}`,
      address: !!this.address ? this.address : '',
      pincode: !!this.pincode ? this.pincode : '',
      country: this.country,
      landmark: !!this.landmark ? this.landmark : '',
      state: this.state,
      city: !!this.city ? this.city : '',
      district: !!this.district ? this.district : '',
      motherPhoneNumber: !!this.motherPhoneNumber ? this.motherPhoneNumber : '',
      fatherPhoneNumber: !!this.fatherPhoneNumber ? this.fatherPhoneNumber : '',
      motherName: !!this.motherName ? this.motherName : '',
      fatherName: !!this.fatherName ? this.fatherName : '',
      dob: this.dob,
      imageUrl: this.imageUrl,
      studentClass: this.studentClass,
      isRefferd: this.isRefferd,
      referrerType: this.referrerType,
      refferdBy: this.refferdBy,
    }
  }
}

export default class CourseLesson {
  constructor(id, lessonData) {
    //console.log('lesson pdf url', lessonData.lessonUrl)
    this.id = id
    this.createdAt = lessonData.createdAt
    this.selectedClasses = lessonData.selectedClasses
    this.selectedBatchId = lessonData.batchId
    this.selectedSubject = lessonData.selectedSubject

    //this.videoUrl = lessonData.videoUrl
    this.lessonTitle = lessonData.lessonTitle
    this.lessonDescription = lessonData.lessonDescription
    this.studentCount = lessonData.studentCount
    this.viewsCount = lessonData.viewsCount
    this.commentsCount = lessonData.commentsCount
    //new
    this.lessonType = lessonData.lessonType

    this.lessonUrl = !!lessonData.lessonUrl
      ? lessonData.lessonUrl.toString()
      : null

    // For course Data
    this.programType = lessonData.programType
    this.programTitle = lessonData.programTitle
    this.programId = lessonData.programId
    this.batchId = lessonData.batchId

    // file
    this.fileName = lessonData.fileName
  }
}

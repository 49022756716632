// Student details dialogue is a componet for adding pending students
// And viewing student details for each student in the course
// We will pass isPendingRequest prop to show and unshow the bottom
// adding or removigng student data.
import { useContext, useState } from 'react'
import { DBContext } from '../../controller/contexts/dbContext'

import { Box, Button, Layer, Text } from 'grommet'
import { Close } from 'grommet-icons'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const StudentDataAlertDialogue = (props) => {
  const classes = useStyles()
  const { closeDialogue, studentData, isPendingRequest } = props
  const {
    addStudentsToTheCourse,
    removeStudentFromTheAppliedList,
    courseBatches,
    fetching,
  } = useContext(DBContext)
  const [error, setError] = useState(null)
  const [selectedBatch, setSelectedBatch] = useState(null)

  // add student to the course
  const addStudent = async () => {
    let error = await addStudentsToTheCourse(studentData, selectedBatch)
    if (error) {
      return alert(error)
    }
    console.log('added student')
    closeDialogue()
  }

  // for changing batch
  const updateBatchData = ({ target }) => {
    setSelectedBatch(target.value)
  }

  // remove student from the list
  const removeStudent = async () => {
    if (!studentData) {
      return
    }
    setError(null)
    let error = await removeStudentFromTheAppliedList(studentData)
    closeDialogue()
    console.log('removed')
    if (error) {
      return alert(error)
    }
  }

  return (
    <Layer margin="small">
      <Box
        width="medium"
        height="large"
        //background="pink"
        pad="small"
        round="medium"
        direction="column"
      >
        <Button onClick={closeDialogue} size="small" alignSelf="end">
          <Close />
        </Button>

        <Box
          flex="grow"
          direction="column"
          pad="small"
          margin={{ top: 'medium' }}
          gap="medium"
        >
          <Box direction="row" justify="between">
            <Text>{'NAME :'}</Text> <Text>{`${studentData.displayName}`}</Text>
          </Box>
          <Box direction="row" justify="between">
            <Text>{'CLASS :'}</Text>
            <Text>{`${
              studentData.studyingClass
                ? studentData.studyingClass.studentClass
                : 'Not Available'
            }`}</Text>
          </Box>
          <Box direction="row" justify="between">
            <Text>{'JOINED BY :'}</Text>
            <Text>{`${studentData.referrerType ? 'By School' : 'Self'}`}</Text>
          </Box>
          <Box direction="row" justify="between">
            <Text>{'SCHOOL NAME'}</Text> :{' '}
            <Text>{`${
              studentData.refferdBy
                ? studentData.refferdBy.schoolName
                : 'Not Available'
            }`}</Text>
          </Box>
          {/* <Box direction="row" justify="between">
            <Text>{'SCHOOL ADDRESS'}</Text> :{' '}
            <Text>{`${
              studentData.schoolData
                ? studentData.schoolData.address
                : 'Not Available'
            }`}</Text>
          </Box> */}
          <Box direction="column" justify="between" gap="small">
            <Text>{'CONTACT NUMBERS :'}</Text>
            <Text>{`${
              studentData.fatherPhoneNumber ? studentData.fatherPhoneNumber : ''
            } ${
              studentData.motherPhoneNumber ? studentData.motherPhoneNumber : ''
            } `}</Text>{' '}
          </Box>

          {isPendingRequest && courseBatches && (
            <Box pad="none">
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label-for-batch">
                  Select Batch
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label-batch"
                  id="demo-simple-select-outlined-batch"
                  value={selectedBatch}
                  onChange={updateBatchData}
                  label="Select Batch"
                >
                  {courseBatches.map((batch) => (
                    <MenuItem value={batch}>{`${batch.title}`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </Box>
        {isPendingRequest && (
          <Box height="40px" direction="row" justify="end" gap="medium">
            <Button
              onClick={removeStudent}
              label="Remove Student"
              size="small"
              color="red"
              plain
            />
            <Button
              onClick={addStudent}
              label="+ Add Student"
              primary
              disabled={selectedBatch === null || fetching}
            />
          </Box>
        )}
      </Box>
    </Layer>
  )
}

export default StudentDataAlertDialogue

import React, { useContext } from 'react'
import { AuthContext } from '../../controller/contexts/authContext'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Box } from 'grommet'
import PrivateRoute from './privateRoute'
import PrivateRouteWithoutSideBar from './privateRouteWithoutSideBar'
import BatchesPage from '../../views/screens/BatchesPage'
import HomePage from '../../views/screens/HomePage'
import StudentsPage from '../../views/screens/StudentsPage'
import TimeTablePage from '../../views/screens/TimeTablePage'
import TeachingPage from '../../views/screens/TeachingPage'
import ExamsPage from '../../views/screens/ExamsPage'
import SettingsPage from '../../views/screens/SettingsPage'
import AuthPage from '../../views/screens/AuthPage'
import SignUpPage from '../../views/screens/SignUp'
import ForgotPassword from '../../views/screens/ForgotPassword'
import NoComponent from '../../views/components/noComponent'
import Welcome from '../../views/screens/Welcome'
import OrgView from '../../views/screens/OrgView'

const HomeRouter = () => {
  return (
    <Router>
      <Box fill>
        <Box flex>
          <Switch>
            <PrivateRoute path="/" exact component={HomePage} />
            <PrivateRoute path="/students" component={StudentsPage} />
            <PrivateRoute path="/time-table" component={TimeTablePage} />
            <PrivateRoute path="/tech" component={TeachingPage} />
            <PrivateRoute path="/exams" component={ExamsPage} />
            <PrivateRoute path="/batches" component={BatchesPage} />
            <PrivateRoute path="/settings" component={SettingsPage} />
            <PrivateRouteWithoutSideBar
              path="/welcome"
              exact
              component={Welcome}
            />
            <PrivateRouteWithoutSideBar
              path="/orgView"
              exact
              component={OrgView}
            />

            <Route path="/auth" exact component={AuthPage} />
            <Route path="/signup" exact component={SignUpPage} />
            <Route path="/forgotPassword" exact component={ForgotPassword} />

            <Route component={NoComponent} />
          </Switch>
        </Box>
      </Box>
    </Router>
  )
}

export default HomeRouter

import React, { useState, useContext } from 'react'

import { Box, Button, Text, DropButton } from 'grommet'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import ConformationAlert from '../conformatinAlert'
import { View, DocumentVideo, DocumentPdf, MoreVertical } from 'grommet-icons'
import { DBContext } from '../../../controller/contexts/dbContext'
var moment = require('moment')

const LessonCard = ({ lesson, openEdit, openCommentsView }) => {
  const [alertOpen, setAlertOpen] = useState(false)
  const { deleteCourseLesson } = useContext(DBContext)

  const handleEdit = (e) => {
    openEdit(lesson)
  }
  // For deleting the lesson
  const handleDelete = async (e) => {
    let error = await deleteCourseLesson(lesson)
    if (error) {
      alert(error)
    }
    closeAlert()
  }
  const openAlert = () => {
    setAlertOpen(true)
  }
  const closeAlert = () => {
    setAlertOpen(false)
  }
  const renderItems = () => (
    <Box gap="small" pad="small">
      <Button onClick={handleEdit}>Edit</Button>
      <Button color="red" onClick={openAlert}>
        Delete
      </Button>
    </Box>
  )

  return (
    <>
      {alertOpen && (
        <ConformationAlert
          action={handleDelete}
          close={closeAlert}
          message="Do you really want to delete this. video"
          actionLable="Delete"
        />
      )}
      <Box
        key={lesson.id}
        pad="small"
        border={{ side: 'bottom' }}
        align="center"
        height="150px"
        width="600px"
        round="small"
      >
        <Box elevation>
          <Box
            width="60px"
            height="60px"
            align="start"
            justify="start"
            direction="row"
            pad="small"
            fill
          >
            <Box pad="small">
              {lesson.lessonType === 'video' ? (
                <DocumentVideo size="50px" color="neutral-2" />
              ) : (
                <DocumentPdf size="50px" color="dark-2" />
              )}
            </Box>
            <Box
              margin={{ start: 'small' }}
              pad="small"
              width="500px"
              height="100%"
              direction="column"
            >
              <Typography variant="h6">{`${lesson.lessonTitle}`}</Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {`${moment(lesson.createdAt.toDate()).format('MMMM Do YYYY')}`}
              </Typography>
              <Box size="20px" flex margin={{ top: 'small' }}>
                <Typography variant="subtitle1" gutterBottom>
                  {`Subject: ${lesson.selectedSubject.toUpperCase()}`}
                </Typography>
              </Box>
            </Box>
            <Box width="80px" justify="between" direction="row" pad="8px">
              {/* <SettingsOption size="20px" /> */}
              {lesson.lessonType === 'video' && (
                <Button onClick={() => openCommentsView(lesson)}>
                  <View size="20px" />
                </Button>
              )}
              <DropButton
                alignSelf="center"
                margin={{ vertical: 'small' }}
                dropContent={renderItems()}
                dropProps={{ align: { top: 'bottom' } }}
              >
                <MoreVertical size="20px" />
              </DropButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
export default LessonCard

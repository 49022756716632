import { Grommet } from 'grommet'
import { AuthProvider } from './controller/contexts/authContext'
import { DBContextProvider } from './controller/contexts/dbContext'
import HomeRouter from './controller/routers/homeRouter'
import { Trash } from 'grommet-icons'
const theme = {
  global: {
    colors: {
      brand: '#228BE6',
    },
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px',
    },
  },
}

function App() {
  return (
    <AuthProvider>
      <DBContextProvider>
        <Grommet theme={theme} full>
          <HomeRouter />
        </Grommet>
      </DBContextProvider>
    </AuthProvider>
  )
}

export default App

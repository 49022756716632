import { useContext, useEffect, useState } from 'react'
import { DBContext } from '../../controller/contexts/dbContext'
import { randomString } from '../../helpers'
import {
  Box,
  Button,
  Layer,
  Text,
  FormField,
  TextInput,
  DateInput,
  Spinner,
} from 'grommet'
import { Close } from 'grommet-icons'

const AddEditCourseBatchComponent = (props) => {
  const { batchData, close, courseId } = props
  const { createNewcourseBatch } = useContext(DBContext)
  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState('')
  const [batchStartingDate, setBatchStartingDate] = useState(null)
  const [batchEndingDate, setBatchEndingDate] = useState(null)
  const [errors, setErrors] = useState([])

  // for setting up data if we are
  // editing
  useEffect(() => {
    console.log('BATCH DATA', batchData)
    if (batchData) {
      setTitle(batchData.title)
      setBatchStartingDate(batchData.batchStartingDate)
      setBatchEndingDate(batchData.batchEndingDate)
    }
  }, [])

  const save = async () => {
    setErrors([])
    let err = []
    if (title.length < 3) {
      err.push('Title should be more then 3 chars')
    }
    if (!batchStartingDate) {
      err.push('Please Add course Starting date')
    }
    if (!batchEndingDate) {
      err.push('Please Add course Ending date')
    }

    console.log(title, batchStartingDate, batchEndingDate)

    if (err.length > 0) {
      return setErrors([...err])
    } else {
      setLoading(true)
      await createNewcourseBatch({
        batchId: `${randomString()}`,
        title: title.toUpperCase(),
        courseId: courseId,
        batchStartingDate: Date.parse(batchStartingDate) ,
        batchEndingDate: Date.parse(batchEndingDate),
      })
      setLoading(false)
      // close the dialogue
      close()
    }
  }

  return (
    <Layer margin="small">
      <Box
        width="medium"
        height="large"
        pad="medium"
        round="medium"
        direction="column"
        overflow="scroll"
      >
        <Button onClick={close} size="small" alignSelf="end" disabled={loading}>
          <Close />
        </Button>

        <Box
          flex="grow"
          direction="column"
          margin={{ top: 'medium' }}
          gap="small"
        >
          <Text size="large">Add/ Edit Course Batch Details</Text>
          <Box height="8px"></Box>
          {errors.length > 0 &&
            errors.map((eachError) => (
              <Text color="red">{`* ${eachError}`}</Text>
            ))}

          <FormField label="Batch Name" required={true}>
            <TextInput
              placeholder="Ex: Batch1"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
            />
          </FormField>

          <FormField label="Course Starting Date" required={true}>
            <DateInput
              format="dd/mm/yyyy"
              value={batchStartingDate}
              onChange={({ value }) => {
                console.log(value)
                setBatchStartingDate(value)
              }}
            />
          </FormField>

          <FormField label="Course Ending Date" required={true}>
            <DateInput
              format="dd/mm/yyyy"
              value={batchEndingDate}
              onChange={({ value }) => {
                console.log(value)
                setBatchEndingDate(value)
              }}
            />
          </FormField>
        </Box>

        <Box height="40px" direction="row" justify="end" gap="medium">
          {/* {batchData && (
            <Button
              onClick={() => {}}
              label="Remove Batch"
              size="small"
              color="red"
              primary
            />
          )} */}
          {loading && <Spinner />}
          {!batchData && (
            <Button
              disabled={loading}
              onClick={save}
              label="+ Create New Batch"
              primary
            />
          )}
        </Box>
      </Box>
    </Layer>
  )
}

export default AddEditCourseBatchComponent

import React, { useContext, useEffect } from 'react'
import { DBContext } from '../../controller/contexts/dbContext'

import {
  Box,
  Text,
  Button,
  InfiniteScroll,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
} from 'grommet'

const PendingStudentsListViewComponent = (props) => {
  const {
    fetchAppliedStudentsList,
    // pendingStudentList,
    fetching,
    selectedCourse,
  } = useContext(DBContext)
  const { showStudentAlert, students } = props
  const step = 25

  useEffect(() => {
    if (selectedCourse) {
      fetchAppliedStudentsList()
    }
  }, [selectedCourse])

  if (!fetching && (!students || students.length < 1)) {
    console.log('Students are:', students)
    return (
      <Box fill justify="center" align="center">
        <Text>No Students Available</Text>
      </Box>
    )
  }

  return (
    <Box fill pad="small" overflow="scroll">
      <Table>
        <TableHeader>
          <TableRow>
            <TableCell scope="col" border="bottom">
              Name
            </TableCell>

            <TableCell scope="col" border="bottom">
              School
            </TableCell>
            <TableCell scope="col" border="bottom">
              Refferd By
            </TableCell>
            <TableCell scope="col" border="bottom">
              Class
            </TableCell>
            <TableCell scope="col" border="bottom">
              Action
            </TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          <InfiniteScroll
            renderMarker={(maker) => (
              <TableRow>
                <TableCell>{maker}</TableCell>
              </TableRow>
            )}
            scrollableAncestor="window"
            items={students}
            step={step}
          >
            {(student) => (
              <TableRow key={student.id}>
                <TableCell>{student.displayName}</TableCell>
                <TableCell>
                  {student.refferdBy
                    ? `${student.refferdBy.schoolName}`
                    : 'No Data'}
                </TableCell>
                <TableCell>
                  {student.referrerType ? `${student.referrerType}` : 'Self'}
                </TableCell>
                <TableCell>
                  {student.studentClass ? `${student.studentClass}` : 'No Data'}
                </TableCell>
                <TableCell>
                  <Button
                    primary
                    label="View"
                    size="small"
                    onClick={() => showStudentAlert(student)}
                  />
                </TableCell>
              </TableRow>
            )}
          </InfiniteScroll>
        </TableBody>
      </Table>
    </Box>
  )
}

export default PendingStudentsListViewComponent

/**
 * NOTE:
 * examSubmissonData.id is Student id
 */

import React, { useState, useContext, useEffect } from 'react'
import { DBContext } from '../../../controller/contexts/dbContext'
import { Box, Image } from 'grommet'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
//import Image from 'material-ui-image'
var moment = require('moment')

const ExamEvalutationDialogue = ({
  close,
  examToView,
  examSubmissonData,
  open,
  saveEvaluation,
}) => {
  const classes = useStyles()
  const [remarks, setRemarks] = useState('')
  const [marks, setMarks] = useState(null)

  useEffect(() => {
    if (examSubmissonData.marks) {
      setMarks(parseInt(examSubmissonData.marks))
    }
    if (examSubmissonData.remarks) {
      setRemarks(examSubmissonData.remarks)
    }
  }, [examSubmissonData.id])

  const handleEvaluation = () => {
    if (marks == null) {
      return alert('Please provide score for the exam')
    } else {
      // save the evaluation data
      close()
      return saveEvaluation(examToView.id, examSubmissonData.id, {
        maxMarks: examToView.maxScore,
        testPercentage: (parseInt(marks) / parseInt(examToView.maxScore)) * 100,
        marks,
        remarks,
      })
    }
  }

  const updateRemarks = (e) => {
    // console.log('remarks', e.target.value);
    setRemarks(e.target.value)
  }
  const updateMarks = (e) => {
    if (parseInt(e.target.value)) {
      setMarks(parseInt(e.target.value))
    } else {
      return alert('Please enter only numbers for marks')
    }
  }
  return (
    <>
      <Dialog
        onClose={close}
        aria-labelledby="customized-dialog-evolve-exam"
        open={open}
        className={classes.padding}
      >
        <DialogTitle id={`${Math.random()}`} onClose={close}>
          {
            <>
              <Typography variant="h5">Evaluate Exam</Typography>
              <Typography variant="body2" color="textSecondary">
                Please check the Exam and give the score
              </Typography>
            </>
          }
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.padding}>
            {/* For showing*/}

            <div>
              {examSubmissonData.answers ? (
                examSubmissonData.answers.map((eachImage) => {
                  return (
                    <div className={classes.image}>
                      <Image src={eachImage} />
                    </div>
                  )
                })
              ) : (
                <div>No images Uploaded</div>
              )}
            </div>

            <Grid item xs={12} sm={10} className={classes.margin}>
              {/* For Giving  Marks*/}
              <TextField
                id={`${Math.random()}`}
                label="Score"
                variant="outlined"
                value={marks}
                onChange={updateMarks}
              />
              {examToView.maxScore && `/ ${examToView.maxScore}`}

              {/* For Entering  Remarks*/}
              <Grid item xs={12} sm={10} className={classes.padding}>
                <TextField
                  id={`${Math.random()}`}
                  label="Remarks"
                  variant="outlined"
                  value={remarks}
                  onChange={updateRemarks}
                  fullWidth
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={examSubmissonData.evaluationDone}
            onClick={handleEvaluation}
            color="primary"
            className={classes.margin}
          >
            {examSubmissonData.evaluationDone ? 'Already Submitted' : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const styles = (theme) => ({
  root: {
    with: '100%',
    margin: 0,
    padding: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogContent = withStyles((theme) => ({
  root: {
    with: '100%',
    padding: theme.spacing(1),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const useStyles = makeStyles((theme) => ({
  root: {
    with: '100%',
    flexGrow: 1,
    marginTop: '8%',
    padding: theme.spacing(1),
    marginBottom: '2%',
  },
  padding: {
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  input: {
    display: 'none',
  },
  image: {
    padding: theme.spacing(1),
    minWidth: 550,
    minHeight: 600,
  },
  margin: {
    margin: 8,
  },
}))

export default ExamEvalutationDialogue

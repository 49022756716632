export let courseData = {
  title: 'Soft skills for job interview ',
  shortDescription: 'Best course in the world',
  whatWillYouLearn: [
    'something',
    'something 2',
    'something 3',
    'something 4',
    'something 5',
  ],
  description:
    'fjdksjakf fjdksjafkldjsaljfdjs jfkldsjaklfjdlskajjfkdljsakljfdjsajfklj kjfkldjslakjfldjsa kdjsklajfkldjsa jfdklsjaklfj jfkdlsjakl fjkdljsakljdsj akfjasj',
  courseIncludes: ['ideas', 'plans', 'concepts'],
  price: 500,
  bannerImageUrl:
    '//s.gravatar.com/avatar/b7fb138d53ba0f573212ccce38a7c43b?s=80',
}

export let courseStudentsList = [
  {
    id: '123456',
    name: 'Tanush Peddiboina',
    studyingClass: {
      id: '2A',
      classNumber: '2',
      section: 'A',
    },
    joinedSelf: false,
    joinedBySchool: true,
    schoolData: {
      schoolId: '',
      name: 'Madhava E.M School',
      address: {},
    },
    parentsContactNumbers: [+919999966666, +919000000000],
  },
  {
    id: '123789',
    name: 'Murari Gangodi',
    studyingClass: {
      id: '3A',
      classNumber: '3',
      section: 'A',
    },
    joinedSelf: true,
    joinedBySchool: false,
    schoolData: {
      schoolId: '',
      name: 'Madhava E.M School',
      address: {},
    },
    parentsContactNumbers: [+919999966666],
  },
  {
    id: '127456',
    name: 'Manjunath Gangodi',
    studyingClass: {
      id: '1A',
      classNumber: '1',
      section: 'B',
    },
    joinedSelf: true,
    joinedBySchool: false,
    schoolData: {
      schoolId: '',
      name: 'Madhava E.M School',
      address: {},
    },
    parentsContactNumbers: [+919999966666],
  },
]

export let appliedStudentsList = [
  {
    id: '873456',
    name: 'Sai Keerthna Gangodi',
    studyingClass: {
      id: '6A',
      classNumber: '6',
      section: 'A',
    },
    joinedSelf: false,
    joinedBySchool: true,
    schoolData: {
      schoolId: '',
      name: 'Madhava E.M School',
      address: {},
    },
    parentsContactNumbers: [+919999966666],
  },
  {
    id: '334512',
    name: 'Penchala Praneetha Peddiboina',
    studyingClass: {
      id: '5A',
      classNumber: '5',
      section: 'A',
    },
    joinedSelf: true,
    joinedBySchool: false,
    schoolData: {
      schoolId: '',
      name: 'Madhava E.M School',
      address: {},
    },
    parentsContactNumbers: [+919999966666, +9198888888],
  },
]

export let batchesFakeData = [
  {
    batchId: `1abc234`,
    title: 'Batch 1',
    batchStartingDate: new Date(),
    batchEndingDate: new Date(),
  },
  {
    batchId: `1abfdsa`,
    title: 'Batch 2',
    batchStartingDate: new Date(),
    batchEndingDate: new Date(),
  },
  {
    batchId: `1a987`,
    title: 'Batch 3',
    batchStartingDate: new Date(),
    batchEndingDate: new Date(),
  },
]



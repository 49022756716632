export default class CourseBatch {
  constructor(id, data) {
    this.id = id
    this.batchId = data.batchId
    this.title = data.title
    this.batchStartingDate = data.batchStartingDate.toDate()
    this.batchEndingDate = data.batchEndingDate.toDate()
  }

  getBatchObject() {
    return {
      batchId: this.batchId,
      batchTitle: this.title,
      batchStartingDate: this.batchStartingDate,
      batchEndingDate: this.batchEndingDate,
    }
  }
}

// batchEndingDate: "2021-05-30T18:30:00.000Z"
// batchId: "cqYtR"
// batchStartingDate: "2021-04-29T18:30:00.000Z"
// createdAt: t {seconds: 1618881283, nanoseconds: 140000000}
// title: "BATCH 1"

import { Box, Button, Heading, Layer, Text } from 'grommet'

const ConformationAlert = (props) => {
  const { action, close, message, actionLable } = props
  return (
    <Layer position="center">
      <Box pad="medium" gap="small" width="medium">
        <Heading level={3} margin="none">
          Confirm
        </Heading>
        <Text>{`${message}`}</Text>
        <Box
          as="footer"
          gap="small"
          direction="row"
          align="center"
          justify="end"
          pad={{ top: 'medium', bottom: 'small' }}
        >
          <Button label="Close" onClick={close} color="dark-3" />
          <Button
            label={
              <Text color="white">
                <strong>{`${actionLable}`}</strong>
              </Text>
            }
            onClick={action}
            primary
            color="status-critical"
          />
        </Box>
      </Box>
    </Layer>
  )
}

export default ConformationAlert

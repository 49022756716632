import { useContext, useEffect, useState } from 'react'
import { DBContext } from '../../controller/contexts/dbContext'
import { Box, Text } from 'grommet'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const BatchSelector = () => {
  const classes = useStyles()

  const { courseBatches, selectedBatch, updateSelectedBatch } = useContext(
    DBContext,
  )

  const updateBatchData = ({ target }) => {
    updateSelectedBatch(target.value)
  }

  if (!courseBatches || courseBatches.length < 1) {
    return <Box></Box>
  }
  return (
    <Box direction="row" width="100%">
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label-for-batch">
          Select Batch
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label-batch"
          id="demo-simple-select-outlined-batch"
          value={selectedBatch}
          onChange={updateBatchData}
          label="Select Subject"
        >
          {courseBatches.map((batch) => (
            <MenuItem value={batch}>{`${batch.title}`}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default BatchSelector

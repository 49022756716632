import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import Chip from '@material-ui/core/Chip'
import { DBContext } from '../../../controller/contexts/dbContext'

const SelectorsForExam = ({
  updateSubjects,

  selectedSubjects,
  noEdit,
}) => {
  const classes = useStyles()
  const { courseSubjects } = useContext(DBContext)

  const handleSubjectUpdate = (event) => {
    updateSubjects(event.target.value)
  }

  const returnSelectors = () => {
    return (
      <>
        <Grid
          container
          spacing={1}
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={5} sm={3}>
            <FormControl className={classes.formControl}>
              <InputLabel id="select-subject-$-mutiple-checkbox-label">
                Select Subjects
              </InputLabel>
              <Select
                labelId="select-subject-$$"
                multiple
                id={`${Math.random()}`}
                value={selectedSubjects}
                onChange={handleSubjectUpdate}
                input={<Input />}
                renderValue={(selected) =>
                  selected.map((item) => item).join(', ')
                }
                MenuProps={MenuProps}
              >
                {courseSubjects
                  ? courseSubjects.map((option) => (
                      <MenuItem
                        key={option + +`${Math.random()}`}
                        value={option}
                        fullWidth
                      >
                        <Checkbox
                          checked={selectedSubjects.indexOf(option) > -1}
                        />
                        <ListItemText primary={option} />
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </>
    )
  }

  const returnSelectedChips = () => {
    return (
      <>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item>
            {selectedSubjects &&
              selectedSubjects.map((eachSubject) => (
                <Chip
                  key={`${eachSubject + `${Math.random()}`} + ${Math.random()}`}
                  label={eachSubject}
                  className={classes.chip}
                  color="secondary"
                />
              ))}
          </Grid>
        </Grid>
      </>
    )
  }
  return (
    <Grid container direction="row">
      {returnSelectors()}
      {returnSelectedChips()}
    </Grid>
  )
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  input: {
    display: 'none',
  },
}))

export default SelectorsForExam

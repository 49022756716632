import { Box, Button as GRButton, InfiniteScroll, Text } from 'grommet'
import { Alert } from 'grommet-icons'
const ErrorMessage = (props) => {
  const { iconComponent, errorMessage } = props
  return (
    <Box
      height="40px"
      width="100%"
      round="small"
      direction="row"
      gap="small"
      justify="start"
      align="center"
      pad="small"
    >
      {iconComponent}
      <Text color="status-warning">{errorMessage}</Text>
    </Box>
  )
}

export default ErrorMessage

import { Box, Text } from 'grommet'

// This is the title for the TAB which contains
// Icon and Title
const RichTabTitle = ({ icon, label }) => (
  <Box direction="row" align="center" gap="xsmall" margin="xsmall">
    {icon}
    <Text size="small">
      <strong>{label}</strong>
    </Text>
  </Box>
)

export default RichTabTitle

import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthContext } from '../contexts/authContext'
import { Box } from 'grommet'

const PrivateRouteWithoutSideBar = ({ component: RouteComponent, ...rest }) => {
  const { currentUser } = useContext(AuthContext)
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        !!currentUser ? (
          <Box fill>
            <RouteComponent {...routeProps} {...rest} />
          </Box>
        ) : (
          <Redirect
            to={{
              pathname: '/auth',
              state: {
                from: routeProps.location,
              },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRouteWithoutSideBar

export default class Exam {
  constructor(id, examData) {
    this.id = id
    this.createdAt = examData.createdAt.toDate()
    this.dueDate = examData.dueDate ? examData.dueDate.toDate() : ''
    this.examTitle = examData.examTitle
    this.isExamEndDate = examData.isExamEndDate
    this.isTimed = examData.isTimed
    this.maxTime = examData.maxTime
    this.questions = examData.questions
    this.selectedClasses = examData.selectedClasses
    this.selectedSubject = examData.selectedSubject
    this.examInstructions = examData.examInstructions
    this.submissonCount = examData.submissonCount
    this.studentCount = examData.studentCount
    this.maxScore = examData.maxScore
    this.passingScore = examData.passingScore
    this.type = examData.type
    this.marksForEachQuestion = examData.marksForEachQuestion
    this.studentScore = examData.studentScore
    this.examSubmissionsCount = examData.examSubmissionsCount

    // For course Data
    this.programType = examData.programType
    this.programTitle = examData.programTitle
    this.programId = examData.programId
    this.batchId = examData.batchId
  }
}

import firebase from 'firebase'

// Your web app's Firebase configuration- OLD
// var firebaseConfig = {
//   apiKey: "AIzaSyBRGIcJxiQ249qalJy2kWOXF0NgYZft_p4",
//   authDomain: "edaffix-21515.firebaseapp.com",
//   databaseURL: "https://edaffix-21515.firebaseio.com",
//   projectId: "edaffix-21515",
//   storageBucket: "edaffix-21515.appspot.com",
//   messagingSenderId: "267087359450",
//   appId: "1:267087359450:web:f5dd5647a7a136625de4e3",
//   measurementId: "G-64HVHJP5VQ"
// };

//Your web app's Firebase configuration- NEW
var firebaseConfig = {
  apiKey: 'AIzaSyBebfefbJjMIszzltT3uPlaruOLV48FDDg',
  authDomain: 'edaffix-c92fe.firebaseapp.com',
  databaseURL: 'https://edaffix-c92fe.firebaseio.com',
  projectId: 'edaffix-c92fe',
  storageBucket: 'edaffix-c92fe.appspot.com',
  messagingSenderId: '850649843717',
  appId: '1:850649843717:web:da6b011ad2391e8212f46c',
  measurementId: 'G-8PWFKVCRHX',
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics()

export default firebase

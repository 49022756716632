var moment = require('moment')

export function randomString(length) {
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghiklmnopqrstuvwxyz'.split(
    '',
  )

  if (!length) {
    length = 5
  }

  var str = ''
  for (var i = 0; i < length; i++) {
    str += chars[Math.floor(Math.random() * chars.length)]
  }
  return str
}

export function getDateString(date) {
  if (date) {
    return moment(date).format('dddd, MMMM Do YYYY, h A')
  } else {
    return 'No Data'
  }
}

function checkEmail(mail) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true
  }

  return false
}

export function validateEmail(email) {
  if (!!email && email.length > 5 && checkEmail(email)) {
    console.log('email valid')
    return true
  } else {
    console.log('email Not valid')
    return false
  }
}

export function validatePassword(pw) {
  if (!!pw && pw.length > 7) {
    console.log('password valid')
    return true
  } else {
    console.log('password Not valid')
    return false
  }
}

export function validateText(inputData) {
  if (!!inputData && inputData.length > 0) {
    console.log(' iput Is valid')
    return true
  } else {
    console.log('iputIsNotvalidvalid')
    return false
  }
}

export function validURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ) // fragment locator
  return !!pattern.test(str)
}

import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthContext } from '../contexts/authContext'
import SideBarComponent from '../../views/components/sidebar'
import { Box } from 'grommet'

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const { currentUser } = useContext(AuthContext)

  return (
    <Box fill>
      <Route
        {...rest}
        render={(routeProps) =>
          !!currentUser ? (
            <Box fill direction="row" pad="0px" margin="0px">
              <SideBarComponent />

              <Box flex>
                <RouteComponent {...routeProps} {...rest} />
              </Box>
            </Box>
          ) : (
            <Redirect
              to={{
                pathname: '/auth',
                state: {
                  from: routeProps.location,
                },
              }}
            />
          )
        }
      />
    </Box>
  )
}

export default PrivateRoute

import React, { useState, useContext, useEffect } from 'react'
import { DBContext } from '../../controller/contexts/dbContext'
import { AuthContext } from '../../controller/contexts/authContext'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  FormField,
  DateInput,
  Button as GRButton,
  Select as GRSelect,
} from 'grommet'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import placeholder from '../../assets/image-placeholder.jpg'
//import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton'
import Select from '@material-ui/core/Select'
//import { DatePicker } from '@material-ui/pickers'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import stateNames from '../../state-names'
import cityNames from '../../cities-name-list'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import LinearProgress from '@material-ui/core/LinearProgress'
import Box from '@material-ui/core/Box'
import { Typography, Toolbar } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import ArrowBack from '@material-ui/icons/ArrowBack'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import NativeSelect from '@material-ui/core/NativeSelect'
const cryptoRandomString = require('crypto-random-string')

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const OrgViewPage = () => {
  const history = useHistory()
  const classes = useStyles()
  const [name, setName] = useState(null)
  const [imageUrl, setImageUrl] = useState('')
  const [subHeading, setSubHeading] = useState(null)
  const [descrepiton, setDescrepiton] = useState(null)
  const [address, setAddress] = useState(null)
  const [orgType, setOrgType] = useState(null)
  const [city, setCity] = useState(null)
  const [state, setState] = useState(null)
  const [town, setTown] = useState(null)
  const [pincode, setPincode] = useState(null)
  const [country, setCountry] = useState('India')
  //const [countryCode, setCountryCode] = useState('91')
  const [phoneNumber1, setPhoneNumber1] = useState(null)
  const [phoneNumber2, setPhoneNumber2] = useState(null)
  const [emailId, setEmainId] = useState(null)
  const [establishedAt, setEstablishedAt] = useState(null)
  const [imageUploading, setImageUploading] = useState(false)
  const [progress, setProgress] = useState(null)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const { currentUser } = useContext(AuthContext)
  const {
    fetching,
    orgData,
    ORG_PROFILE_IMAGE_BUCKET,
    createOrganization,
    updateOrgData,
  } = useContext(DBContext)

  useEffect(() => {
    console.table(orgData)
    if (!!orgData) {
      setName(orgData.name)
      setImageUrl(orgData.imageUrl)
      setSubHeading(orgData.subHeading)
      setDescrepiton(orgData.descrepiton)
      setAddress(orgData.address)
      setOrgType(orgData.orgType)
      setCity(orgData.city)
      setState(orgData.state)
      setTown(orgData.town)
      setPincode(orgData.pincode)
      setCountry(orgData.country)
      //setCountryCode(orgData.countryCode)
      setPhoneNumber1(orgData.phoneNumber1)
      setPhoneNumber2(orgData.phoneNumber2)
      setEmainId(orgData.emailId)
      setEstablishedAt(orgData.establishedAt)
    } else {
      if (currentUser.email) {
        setEmainId(currentUser.email)
      }
    }
  }, [orgData])

  /** Functions to handle file uploads */
  // const handleUploadStart = () => {
  //   setProgress(0)
  //   setImageUploading(true)
  // }
  // const handleProgress = (progress) => setProgress(progress)
  // const handleUploadError = (error) => {
  //   setProgress(0)
  //   setImageUploading(false)
  //   console.error(error)
  //   alert(error)
  // }

  // const handleUploadSuccess = (filename) => {
  //   setProgress(100)
  //   setImageUploading(false)
  //   ORG_PROFILE_IMAGE_BUCKET.child(filename)
  //     .getDownloadURL()
  //     .then((url) => {
  //       console.log('Url', url)
  //       setImageUrl(url)
  //     })
  // }

  const handleOrgTypeChange = (e) => {
    const type = e.target.value
    setOrgType(type)
  }

  // evaluate the inputs and return true if all requirements met
  const evaluateInputs = async () => {
    if (name === null || name.length < 5) {
      return alert('Please enter the valid name of the organization')
    }
    if (imageUrl === null) {
      return alert('Please Upload an image that represent your organization')
    }
    if (subHeading === null || subHeading.length < 5) {
      return alert('Please enter a breaf introduction about your organization')
    }

    if (descrepiton === null || descrepiton.length < 5) {
      return alert('Please enter the valid descreption of the organization')
    }

    if (address === null || address.length < 5) {
      return alert('Please enter the valid address of the organization')
    }
    if (orgType === null || orgType.length < 5) {
      return alert('Please select the type of the organization')
    }
    if (city === null || city.length < 5) {
      return alert('Please select the city/district name')
    }
    if (town === null || town.length < 5) {
      return alert('Please enter the valid town/city name')
    }
    if (state === null || state.length < 5) {
      return alert('Please select the state ')
    }
    if (pincode === null || pincode.length < 5) {
      return alert('Please enter the valid pin/zip code')
    }
    if (phoneNumber1 === null || phoneNumber1.length !== 10) {
      return alert('Please enter the valid 10 digit phone number')
    }
    return true
  }

  const saveOrgData = async () => {
    const isEvaluated = await evaluateInputs()
    console.log(isEvaluated)
    if (isEvaluated !== true) {
      return null
    } else {
      const established = establishedAt != null ? establishedAt : new Date.now()
      const payload = {
        name,
        imageUrl,
        subHeading,
        descrepiton,
        address,
        orgType,
        city,
        state,
        town,
        pincode,
        country,
        phoneNumber1,
        phoneNumber2,
        emailId,
        establishedAt: established,
      }
      let error = null
      if (orgData) {
        // update org Data
        error = await updateOrgData({ ...payload }, orgData.id)
      } else {
        // Create new Organization Data
        error = await createOrganization({ ...payload })
      }

      if (!error) {
        return setSnackbarOpen(true)
      } else {
        return alert(`Oops! Error Present while saving ! Eror: ${error}`)
      }
    }
  }

  const orgDetailsEntry = () => {
    return (
      <div>
        <Box my={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  {imageUploading ? (
                    <CircularProgress />
                  ) : (
                    <Avatar
                      alt="Icon"
                      src={imageUrl != null ? imageUrl : placeholder}
                      className={classes.bigAvatar}
                    />
                  )}
                </Grid>
                {/* For upload button */}
                <Grid item>
                  {/* <CustomUploadButton
                    hidden
                    accept="image/*"
                    maxHeight="350"
                    maxWidth="350"
                    filename={(file) => cryptoRandomString({ length: 11 })}
                    storageRef={ORG_PROFILE_IMAGE_BUCKET}
                    onUploadStart={handleUploadStart}
                    onUploadError={handleUploadError}
                    onUploadSuccess={handleUploadSuccess}
                    onProgress={handleProgress}
                    style={{ color: 'white', borderRadius: 4 }}
                  >
                    <Button component="span">UPLOAD/CHANGE</Button>
                  </CustomUploadButton>
                  <Typography paragraph variant="caption" color="textSecondary">
                    {' '}
                    {'(350 * 350 px)'}
                  </Typography> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography color="textSecondary" className={classes.icon}>
                {' '}
                Organization type
              </Typography>
              <Select
                labelId={`${Math.random()}`}
                id={`${Math.random()}`}
                value={orgType}
                onChange={handleOrgTypeChange}
                fullWidth
              >
                <MenuItem value={'INDIVIDUAL'}>INDIVIDUAL</MenuItem>
                <MenuItem value={'INSTITUTION'}>INSTITUTION</MenuItem>
              </Select>
              <Box pt={1}>
                <Typography paragraph variant="caption" color="textSecondary">
                  * INSTITUTION: Government recognized/registerd
                  organization/socity{' '}
                </Typography>
              </Box>

              <Typography variant="caption" color="textSecondary">
                * INDIVIDUAL: Run by a person or a group of people
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id={`${Math.random()}`}
                name="Org Name"
                label="Name of the organization"
                value={name}
                onChange={(event) => setName(event.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id={`${Math.random()}`}
                name="subheading"
                label="Short descrepiton"
                value={subHeading}
                onChange={(event) => setSubHeading(event.target.value)}
                fullWidth
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                required
                id={`${Math.random()}`}
                name="about"
                label="Descrepiton"
                value={descrepiton}
                onChange={(event) => setDescrepiton(event.target.value)}
                fullWidth
                multiline
                rows={4}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormField label="Organization Starting Date" required={true}>
                <DateInput
                  format="dd/mm/yyyy"
                  value={!!establishedAt ? establishedAt : new Date()}
                  onChange={({ value }) => {
                    console.log(value)
                    setEstablishedAt(value)
                  }}
                />
              </FormField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id={`${Math.random()}`}
                name="address"
                label="Address"
                value={address}
                onChange={(event) => setAddress(event.target.value)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                required
                id={`${Math.random()}`}
                name="town"
                label="Town/Village"
                value={town}
                onChange={(event) => setTown(event.target.value)}
                fullWidth
                autoComplete="billing address-level2"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <GRSelect
                placeholder="City/District"
                options={[...cityNames]}
                value={city}
                onChange={({ option }) => setCity(option)}
              />

              {/* <NativeSelect
                  required
                  labelId={`${Math.random()}`}
                  id={`${Math.random()}`}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  fullWidth
                >
                  {cityNames.map((eachCity) => (
                    <MenuItem
                      key={`${Math.random()}`}
                      value={!!eachCity ? eachCity : ''}
                    >
                      {eachCity}
                    </MenuItem>
                  ))}
                </NativeSelect> */}
            </Grid>
            <Grid item xs={12} sm={6}>
              <GRSelect
                placeholder="State"
                options={[...stateNames]}
                value={state}
                onChange={({ option }) => setState(option)}
              />
              {/* <FormControl className={classes.formControl}>
                <InputLabel htmlFor="age-native-simple">State</InputLabel>
                <Select
                  required
                  labelId={`${Math.random()}`}
                  id={`${Math.random()}`}
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  fullWidth
                >
                  {stateNames.map((eachState) => (
                    <MenuItem
                      key={`${Math.random()}`}
                      value={eachState ? eachState : ''}
                    >
                      {eachState}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id={`${Math.random()}`}
                name="zip"
                label="Zip/Postal code"
                value={pincode}
                onChange={(event) => setPincode(event.target.value)}
                fullWidth
                autoComplete="billing postal-code"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                disabled={true}
                required
                id={`${Math.random()}`}
                name="country"
                label="Country"
                fullWidth
                value={country}
                onChange={(event) => setCountry(event.target.value)}
                autoComplete="billing country"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id={`${Math.random()}`}
                name="phoneNumber1"
                label="Phone number 1"
                helperText="Enter 10 digit phone Number"
                value={phoneNumber1}
                onChange={(event) => setPhoneNumber1(event.target.value)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                id={`${Math.random()}`}
                name="phoneNumber2"
                label="Phone number 2"
                helperText="Enter 10 digit phone Number"
                value={phoneNumber2}
                onChange={(event) => setPhoneNumber2(event.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id={`${Math.random()}`}
                name="email"
                label="Email Address"
                value={emailId}
                onChange={(event) => setEmainId(event.target.value)}
                fullWidth
                autoComplete="billing address-level2"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box
                mx={2}
                p={2}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={saveOrgData}
                  disabled={fetching || imageUploading}
                >
                  {orgData ? 'Update Data' : 'Save details'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    )
  }
  // Go back to welcome page
  const handleBackButtonClicked = async () => {
    return history.replace('/welcome')
  }

  const handleSnackBarClose = async () => {
    setSnackbarOpen(false)
  }

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleSnackBarClose}
      >
        <Alert onClose={handleSnackBarClose} severity="success">
          Succesfully Saved!
        </Alert>
      </Snackbar>

      <Toolbar>
        <IconButton
          edge="start"
          onClick={handleBackButtonClicked}
          color="inherit"
          aria-label="menu"
        >
          <ArrowBack />
        </IconButton>
        <Typography variant="body1" className={classes.title}>
          Back
        </Typography>
        <Button color="inherit">Logout</Button>
      </Toolbar>

      <Container component="main" maxWidth="sm">
        <CssBaseline />
        {(fetching || imageUploading) && <LinearProgress />}
        {orgDetailsEntry()}
      </Container>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  bigAvatar: {
    display: 'flex',
    alignContent: 'center',
    width: 120,
    height: 120,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  title: {
    flexGrow: 1,
  },
}))

export default OrgViewPage

import {
  Box,
  Button,
  Text,
  Table,
  Main,
  InfiniteScroll,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
} from 'grommet'
import {
  CircleInformation,
  StatusGood,
  Currency,
  Group,
  UserAdd,
  Run,
  Upgrade,
  Support,
} from 'grommet-icons'

import { Trash } from 'grommet-icons'
import { useState, useContext, useEffect } from 'react'
import { DBContext } from '../../controller/contexts/dbContext'
import InfoTile from '../components/infoTile'
import AddEditCourseBatchComponent from '../components/addEditCourseBatch'
import { getDateString } from '../../helpers'
//var moment = require('moment')

const BatchesPage = () => {
  const step = 25
  const {
    courseBatches,
    selectedBatch,
    selectedCourse,
    createNewcourseBatch,
    updateSelectedBatch,
    //removeBatchData,
  } = useContext(DBContext)
  const [isAddEditBatchAleartOpen, setIsAddEditBatchAlertOpen] = useState(false)
  const [batch, setBatch] = useState(null)

  //useEffect(() => {}, [])

  /**
   *
   * @param {Object} batchData
   * For opening and closing batch aleart
   */
  const openCrateNewBatchAleart = (data) => {
    //console.log('openAddEditBatchAleart')

    setIsAddEditBatchAlertOpen(true)
  }
  const openEditBatchAleart = (data) => {
    console.log('openAddEditBatchAleart')

    setBatch(data)
    setIsAddEditBatchAlertOpen(true)
  }
  // Closes the alert dialogue
  const closeAddEditBatchAleart = () => {
    setBatch(null)
    setIsAddEditBatchAlertOpen(false)
  }

  const renderTopInfoRow = () => {
    return (
      <Box direction="row" height="100%" gap="medium" justify="between">
        <Box
          pad="none"
          background="dark-3"
          height="100%"
          width="medium"
          round="small"
          justify="center"
          align="center"
        >
          <InfoTile
            icon={<Run size="40px" />}
            number={courseBatches ? `${courseBatches.length}` : '0'}
            title="Batches Live"
          />
        </Box>
        <Box
          pad="none"
          background="dark-3"
          height="100%"
          width="medium"
          round="small"
          justify="center"
          align="center"
        >
          <InfoTile
            icon={<Upgrade size="40px" />}
            number={'Nan'}
            title="Upcomming Batches"
          />
        </Box>
        <Box
          pad="none"
          background="dark-3"
          height="100%"
          width="medium"
          round="small"
          justify="center"
          align="center"
        >
          <InfoTile
            icon={<Support size="40px" />}
            number={`0`}
            title="Finished Batches"
          />
        </Box>
      </Box>
    )
  }

  const renderBatchesTable = () => {
    if (!selectedCourse) {
      return (
        <Box fill justify="center" align="center">
          <Text>Please create new course in SETTINGS page.</Text>
        </Box>
      )
    }
    if (!courseBatches || courseBatches.length < 1) {
      return (
        <Box fill justify="center" align="center">
          <Text>
            No Course Batches Available, Please create new course batch.
          </Text>
        </Box>
      )
    }
    return (
      <Box fill pad="small">
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell scope="col" border="bottom">
                Batch Name
              </TableCell>

              <TableCell scope="col" border="bottom">
                Starting Date
              </TableCell>
              <TableCell scope="col" border="bottom">
                Ending Date
              </TableCell>

              <TableCell scope="col" border="bottom">
                Action
              </TableCell>
            </TableRow>
          </TableHeader>

          <TableBody>
            <InfiniteScroll
              renderMarker={(maker) => (
                <TableRow>
                  <TableCell>{maker}</TableCell>
                </TableRow>
              )}
              scrollableAncestor="window"
              items={courseBatches}
              step={step}
            >
              {(eachBatch) => (
                <TableRow key={eachBatch.batchId}>
                  <TableCell>
                    {eachBatch.title ? `${eachBatch.title}` : 'No Datas'}
                  </TableCell>
                  <TableCell>
                    {`${getDateString(eachBatch.batchStartingDate)}`}
                  </TableCell>

                  <TableCell>
                    {`${getDateString(eachBatch.batchEndingDate)}`}
                  </TableCell>
                  <TableCell>
                    <Button
                      primary
                      label="View"
                      size="small"
                      onClick={() => openEditBatchAleart(eachBatch)}
                    />
                  </TableCell>
                </TableRow>
              )}
            </InfiniteScroll>
          </TableBody>
        </Table>
      </Box>
    )
  }

  return (
    <Main>
      {isAddEditBatchAleartOpen && (
        <AddEditCourseBatchComponent
          batchData={batch}
          courseId={selectedCourse.id}
          close={closeAddEditBatchAleart}
        />
      )}
      <Box
        direction="column"
        pad="small"
        //background="pink"
        height="100%"
        gap="medium"
      >
        <Box pad="xsmall" height="90px">
          {renderTopInfoRow()}
        </Box>

        <Box pad="small" flex="grow">
          {renderBatchesTable()}
        </Box>
        <Box pad="xsmall" margin="none">
          <Button
            alignSelf="start"
            label=" + Crate New Batch"
            onClick={() => openCrateNewBatchAleart(null)}
          />
        </Box>
      </Box>
    </Main>
  )
}

export default BatchesPage

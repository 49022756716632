const NoComponent = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
      }}
    >
      <h2>Sorry! This feature is not available now</h2>
    </div>
  )
}

export default NoComponent

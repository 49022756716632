import React, { useState, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { DBContext } from '../../../controller/contexts/dbContext'
import CssBaseline from '@material-ui/core/CssBaseline'
import Button from '@material-ui/core/Button'
import { Box, Button as GRButton, InfiniteScroll, Text } from 'grommet'
import { Alert, SettingsOption, View } from 'grommet-icons'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import LinearProgress from '@material-ui/core/LinearProgress'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import SelectorsForExam from './selectorsForExam'
import SubjectSelector from '../subjectSelector'
import List from '@material-ui/core/List'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import QuestionEntryEditDialogue from './questionEntryEdit'
import QuestionTile from './questionTile'
//import { DateTimePicker } from '@material-ui/pickers';
import SendIcon from '@material-ui/icons/Send'
import Select from '@material-ui/core/Select'
import NativeSelect from '@material-ui/core/NativeSelect'
import InputLabel from '@material-ui/core/InputLabel'
//import WrittenExamEntryEdit from './writtenExamEntryEdit'

var moment = require('moment')

const CreateOrEditExam = ({ togleExamEntryDialogue, examToedit }) => {
  const classes = useStyles()
  const {
    createExam,
    updateExam,
    selectedCourse,
    fetching,
    selectedBatch,
  } = useContext(DBContext)

  const [loading, setLoading] = useState(false)

  const [selectedSubject, setSelectedSubject] = useState(null)
  const [isTimed, setIsTimed] = useState(false)
  const [isExamEndDate, setIsExamEndDate] = useState(false)
  const [
    isQuestionEntryEditDialogueOpen,
    setQuestionEntryEditDialogueOpen,
  ] = useState(false)
  const [examTitle, setExamTitle] = useState('')
  const [examInstructions, setExamInstructions] = useState('')
  const [maxTime, setMaxTime] = useState(null)
  const [dueDate, setDueDate] = useState('')
  const [questions, setQuestions] = useState([])
  const [selectedQuestionToEdit, setQuestionToEdit] = useState(null)
  const [studentCount, setStudentCount] = useState(0)
  const [type, setExamType] = useState('MULTIPLECHOICE')
  const [maxScore, setMaxScore] = useState(null)
  const [passingScore, setPassingScore] = useState(null)

  //let selectedSchool = allSchoolsData[schoolIndex]

  useEffect(() => {
    console.log(examToedit)
    if (!!examToedit) {
      setSelectedSubject(examToedit.selectedSubject)
      setIsTimed(examToedit.isTimed)
      setIsExamEndDate(examToedit.isExamEndDate)
      setExamTitle(examToedit.examTitle)
      setExamInstructions(examToedit.examInstructions)
      setMaxTime(examToedit.maxTime)
      setDueDate(examToedit.dueDate)
      setQuestions(examToedit.questions)
      setStudentCount(examToedit.studentCount)
      //setExamType(examToedit.type)
      setMaxScore(examToedit.maxScore)
      setPassingScore(examToedit.passingScore)
    }
  }, [])
  /**
   *
   * @returns For saving exam data
   */
  const handleSave = async () => {
    //console.log('momentdifference:', moment(dueDate).diff(moment(), 'hours'));
    if (examTitle.length < 5) {
      return alert('Title of the exam is too short')
    }
    if (examInstructions.length < 10) {
      return alert('Please provide detail instructions for exam')
    }
    if (selectedSubject === null) {
      return alert('Please select the subject')
    }
    // if (isTimed === true && maxTime <= 0) {
    //   return alert(
    //     'You selcted this exam is timed, please enter maximum available time',
    //   )
    // }
    // if (isExamEndDate === true && moment(dueDate).diff(moment(), 'hours') < 2) {
    //   return alert(
    //     'Please make sure that the end date is more then 2 hour from now ',
    //   )
    // }
    if (questions.length < 1) {
      return alert('Too few questons. Please add more questions ')
    }
    if (type === null) {
      return alert('Please select exam type')
    }
    if (passingScore === null) {
      return alert('Please select passiong score')
    }
    if (maxScore === null || maxScore === 0) {
      return alert('Please enter maximum score of the exam')
    }

    const payload = {
      selectedSubject,
      isTimed,
      isExamEndDate,
      examTitle,
      maxTime,
      dueDate,
      questions,
      examInstructions,
      studentCount,
      examType: type,
      passingScore,
      maxScore,
    }

    console.log(payload)
    let err = null
    if (!!examToedit && examToedit.id) {
      setLoading(true)
      err = await updateExam(payload, examToedit.id)
      setLoading(false)
    } else {
      setLoading(true)
      err = await createExam({ ...payload })
      setLoading(false)
    }
    if (err) {
      alert(`${err}`)
    }

    togleExamEntryDialogue()
  }

  /**
   *
   * @param {Bool} event
   * If the timed is checked  it set maximum time to 100
   * and if checke is false it will set the time to ''
   *
   * If due date is present, it will set date to tomorrow date
   * as default and reset to '' if it unchecked;
   */
  // const handleChekBox = (event) => {
  //   if (event.target.name === 'timed') {
  //     setIsTimed(event.target.checked)
  //     if (event.target.checked) {
  //       setMaxTime(100)
  //     } else {
  //       setMaxTime('')
  //     }
  //   }
  //   if (event.target.name === 'examEndDate') {
  //     setIsExamEndDate(event.target.checked)
  //     if (event.target.checked) {
  //       const tomorrow = moment()
  //         .add(1, 'd') //replace 2 with number of days you want to add
  //         .toDate()

  //       setDueDate(tomorrow)
  //     } else {
  //       setDueDate('')
  //     }
  //   }
  // }

  // const handleChange = (name) => (event) => {
  //   if (name === 'time') {
  //     !!event.target.value
  //       ? setMaxTime(parseInt(event.target.value))
  //       : setMaxTime(null)
  //   }
  //   if (name === 'type') {
  //     console.log('type changed')
  //     setExamType(event.target.value)
  //     //NOTE: We will reset All questions if we change
  //     // the type to avoid conflicts between
  //     // two type of questions.
  //     setQuestions([])
  //   }
  // }
  const setExamPassingScore = (event) => {
    !!event.target.value
      ? setPassingScore(parseInt(event.target.value))
      : setPassingScore(null)
  }
  const setExamTotalScore = (event) => {
    !!event.target.value
      ? setMaxScore(parseInt(event.target.value))
      : setMaxScore(null)
  }

  // const handleDateChange = (date) => {
  //   setDueDate(date.toDate())
  // }
  const closeQuestionEntryDialogue = () => {
    console.log('The EntryDialogue should close')
    setQuestionEntryEditDialogueOpen(false)
  }
  const openQuestionEntryDialogue = () => {
    console.log('openQuestionEntryDialogue')
    if (type === null) {
      return alert('Please selece exam type')
    }
    setQuestionToEdit(null)
    setQuestionEntryEditDialogueOpen(true)
  }

  // For adding question to the list
  const addQuestion = (question) => {
    //console.log('question', question);

    setQuestions([...questions, question])

    closeQuestionEntryDialogue()
  }
  // For updating the question data
  const updateQuestion = (question) => {
    let index
    let totalQuestions = [...questions]

    for (var i = 0; i < totalQuestions.length; i++) {
      if (totalQuestions[i].id === question.id) {
        index = i
      }
    }

    if (index >= 0) {
      totalQuestions[index] = question
      setQuestions([...totalQuestions])
      console.log('changing')
    }
    closeQuestionEntryDialogue()
  }

  const returnQuestionEntryEditDialogue = () =>
    isQuestionEntryEditDialogueOpen && (
      <QuestionEntryEditDialogue
        open={isQuestionEntryEditDialogueOpen}
        closeDialogue={closeQuestionEntryDialogue}
        questionData={selectedQuestionToEdit}
        addQuestionToQuestionsList={addQuestion}
        update={updateQuestion}
      />
    )

  const openToEditDialogue = (questionToEdit) => {
    //console.log('open to edit is ', questionToEdit)
    setQuestionToEdit(questionToEdit)
    setQuestionEntryEditDialogueOpen(true)
  }

  const deleteQuestion = (questionIdToDelete) => {
    const questionsAfterDelete = questions.filter(
      (item) => item.id !== questionIdToDelete,
    )

    //console.log('questionsAfterDelete', questionsAfterDelete);
    setQuestions([...questionsAfterDelete])
  }

  const returnQuestionsList = () => {
    if (questions.length < 1) {
      return (
        <List>
          <Typography variant="subtitle1" color="textSecondary">
            No Questions cerated yet! Please add questions.
          </Typography>
        </List>
      )
    } else {
      return (
        <List>
          {questions.map((eachQuestion) => {
            // console.log('eachQuestion', eachQuestion);
            return (
              <QuestionTile
                key={`${Math.random()}+EXAM`}
                questionData={eachQuestion}
                openToEdit={openToEditDialogue}
                deleteQuestion={deleteQuestion}
                type={type}
              />
            )
          })}
        </List>
      )
    }
  }

  return (
    <>
      <Button
        size="small"
        disabled={fetching || loading}
        variant="contained"
        color="primary"
        className={classes.tollbarButton}
        onClick={handleSave}
        endIcon={<SendIcon />}
      >
        {!!examToedit ? 'Update Exam' : 'Send Exam'}
      </Button>
      <CssBaseline />
      <main className={classes.layout}>
        {loading && <LinearProgress />}

        {returnQuestionEntryEditDialogue()}

        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography component="h1" variant="h5">
              {examToedit ? 'Update Exam' : 'Create Exam'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              variant="outlined"
              id="exam-name"
              name="exam-title"
              label="Exam Title"
              value={examTitle}
              onChange={(event) => setExamTitle(event.target.value)}
              fullWidth
              multiline
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              variant="outlined"
              id="exam-instructions"
              name="exam-instructions"
              label="Exam Instructions"
              value={examInstructions}
              onChange={(event) => setExamInstructions(event.target.value)}
              fullWidth
              rows={4}
              multiline
            />
          </Grid>
          <Grid item xs={6} sm={6} style={{ padding: 0 }}>
            <SubjectSelector
              subjects={selectedCourse.subjects}
              setSubjectData={setSelectedSubject}
              selectedSubject={selectedSubject}
            />
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={2}
              directon="column"
              alignItems="flex-start"
              className={classes.padding}
            >
              {/* <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isTimed}
                      onChange={handleChekBox}
                      name="timed"
                      color="primary"
                    />
                  }
                  label="Timed Exam"
                />
              </Grid> */}
              {/* {isTimed && (
                <Grid item xs={12} sm={12}>
                  <Input
                    id="standard-adornment-time"
                    value={maxTime}
                    onChange={handleChange('time')}
                    endAdornment={
                      <InputAdornment position="end">Min</InputAdornment>
                    }
                    aria-describedby="standard-time-helper-text"
                  />
                </Grid>
              )} */}

              {/* <Grid item xs={12} sm={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isExamEndDate}
                      onChange={handleChekBox}
                      name="examEndDate"
                      color="primary"
                    />
                  }
                  label="Exam Having End Date"
                />
              </Grid>
              {isExamEndDate && (
                <Grid item xs={12} sm={12}>
                  <div>
                    <DateTimePicker
                    fullWidth
                    variant='dialog'
                    id="date-picker-inline-Exam"
                    ampm={false}
                    label="Select Exam End Date"
                    value={dueDate}
                    onChange={handleDateChange}
                    onError={console.log}
                    disablePast
                    format="dddd, MMMM Do YYYY, h:mm"

                  />
                  </div>
                </Grid>
              )} */}

              {/* <Grid item xs={12} sm={12}>
                <InputLabel id="demo-simple-select-label">Exam Type</InputLabel>
                <Select
                  native
                  value={type}
                  onChange={handleChange('type')}
                  label="Exam Type"
                  inputProps={{
                    name: 'type',
                    id: 'age-native-simple-exam',
                  }}
                >
                  <option aria-label="None" value="" />
                  <option value={'MULTIPLECHOICE'}>Multiple Choice</option>
                  <option value={'WRITTEN'}>Written</option>
                </Select>
              </Grid> */}

              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  variant="outlined"
                  id="exam-iquestion needs to ansewer to pass"
                  name="exam-question-needs-to-ansewer -to pass"
                  value={passingScore}
                  onChange={setExamPassingScore}
                />

                {type === 'MULTIPLECHOICE' ? (
                  <div>
                    <Typography variant="caption">
                      {' '}
                      *Minimum question needs{' '}
                    </Typography>
                    <Typography variant="caption">
                      {' '}
                      to be answered to pass this exam
                    </Typography>
                  </div>
                ) : (
                  <div>
                    <Typography variant="caption">
                      {' '}
                      *Minimum score needs{' '}
                    </Typography>
                    <Typography variant="caption">
                      {' '}
                      to pass this exam
                    </Typography>
                  </div>
                )}
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  variant="outlined"
                  id="exam-iquestion needs to ansewer to pass"
                  name="exam-question-needs-to-ansewer -to pass"
                  value={maxScore}
                  onChange={setExamTotalScore}
                />

                <div>
                  <Typography variant="caption"> *Maximum score </Typography>
                  <Typography variant="caption"> of this exam</Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>

          {/* Bottom question Section  */}
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Typography component="h2" variant="inherit">
                  {`Questions (${questions.length}):`}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Button
                  variant="text"
                  color="primary"
                  onClick={openQuestionEntryDialogue}
                >
                  {type === 'WRITTEN'
                    ? '+ Add Qustions Section'
                    : '+ Add New Question'}
                </Button>
              </Grid>
              <Grid item xs={12} sm={12}>
                {/* For showing questions  */}
                {returnQuestionsList()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </main>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  submitButton: {
    margin: 8,
  },
  submit: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tollbarButton: {
    marginLeft: '70%',
  },
}))

export default CreateOrEditExam

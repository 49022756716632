import React, { useState, useEffect, useContext } from 'react'
import { Box, Button as GRButton, InfiniteScroll, Text } from 'grommet'
import { Alert } from 'grommet-icons'
import { DBContext } from '../../controller/contexts/dbContext'
import BatchSelector from '../components/batchSelector'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import CreateOrEditExam from '../components/Exams/createExam'
import Toolbar from '@material-ui/core/Toolbar'
import ExamIcon from '@material-ui/icons/NoteAddRounded'
import ExamDisplayTile from '../components/Exams/examDisplayTile'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import ViewExamSubmissionsPage from '../components/Exams/viewExamSubmissionsPage'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
const ExamsPage = (props) => {
  const classes = useStyles()
  const {
    selectedCourse,
    selectedBatch,
    fetching,
    fetchExamsData,
    examsList,
    deleteExam,
  } = useContext(DBContext)

  const [examToViewAndEdit, setExamToViewAndEdit] = useState(null)
  const [isExamCreatingDialogueOpen, setIsExamCreatingDialogueOpen] = useState(
    false,
  )
  const [
    isExamSubmissionsDialogueOpen,
    setIsExamSubmissionsDialogueOpen,
  ] = useState(false)
  const [examToViewSubmisssions, setExamToViewSubmisssions] = useState('')
  const [examSubmissions, setExamSubmissions] = useState(null)

  // Fetches course exams whenever the batch
  // changes.
  useEffect(() => {
    if (selectedBatch && selectedCourse) {
      fettchInitialData()
    }
  }, [selectedBatch])

  const fettchInitialData = async () => {
    const error = await fetchExamsData()
    if (error) {
      return alert(error)
    }
  }

  const togleExamEntryDialogue = () => {
    setIsExamCreatingDialogueOpen(
      (isExamCreatingDialogueOpen) => !isExamCreatingDialogueOpen,
    )
  }
  const onCreateExamButtonPressed = () => {
    setExamToViewAndEdit(null)
    togleExamEntryDialogue()
  }

  const handleExamDelete = (examId) => {
    deleteExam(examId)
  }

  // For opening Exam Edit dialogue
  const handleExamEdit = (examDataToEdit) => {
    setExamToViewAndEdit(examDataToEdit)
    setIsExamCreatingDialogueOpen(true)
  }

  const returnExamCreatingDialogue = () => {
    return (
      <>
        <Dialog
          fullScreen
          TransitionComponent={Transition}
          aria-labelledby="customized-dialog-title-examEntry"
          open={isExamCreatingDialogueOpen}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              edge="end"
              onClick={togleExamEntryDialogue}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography>Close</Typography>
          </Toolbar>

          <DialogContent>
            <CreateOrEditExam
              togleExamEntryDialogue={togleExamEntryDialogue}
              examToedit={examToViewAndEdit}
            />
          </DialogContent>
        </Dialog>
      </>
    )
  }
  const returnExamSubmissionsViewDialogue = () => {
    return (
      <>
        <Dialog
          fullScreen
          TransitionComponent={Transition}
          aria-labelledby="customized-dialog-title-examView"
          open={isExamSubmissionsDialogueOpen}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              edge="end"
              onClick={closeExamSubmissionsDialogue}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography>Close</Typography>
          </Toolbar>

          <DialogContent>
            <ViewExamSubmissionsPage
              close={closeExamSubmissionsDialogue}
              examToView={examToViewSubmisssions}
              examSubmissionsData={examSubmissions}
            />
          </DialogContent>
        </Dialog>
      </>
    )
  }

  // For opening of Exam submission dialogue
  const handleExamSubmissionsDialogueOpen = (examData, examSubmissionsData) => {
    console.log('handleExamSubmissionsDialogueOpen')
    setExamToViewSubmisssions(examData)
    setExamSubmissions(examSubmissionsData)
    setIsExamSubmissionsDialogueOpen(true)
  }

  // For closing of Exam submission dialogue
  const closeExamSubmissionsDialogue = () => {
    setExamToViewSubmisssions(null)
    setIsExamSubmissionsDialogueOpen(false)
  }

  const loadMoreExams = () => {
    console.log('loading more exams')
  }

  const renturnExamsList = () => {
    if (examsList.length < 1 && !fetching) {
      return <Typography> No Exam Created Yet</Typography>
    }
    if (fetching) {
      return <Typography> Fetching Data</Typography>
    }

    return (
      <InfiniteScroll items={[...examsList]} onMore={loadMoreExams} {...props}>
        {(item) => (
          <Box
            key={`${Math.random()}`}
            pad="medium"
            border={{ side: 'bottom' }}
            align="center"
          >
            {/*  Render list item to show exam title and other details */}
            <ExamDisplayTile
              openToEdit={handleExamEdit}
              examData={item}
              deleteExam={handleExamDelete}
              className={classes.tile}
              openExamSubmissionsDialogue={handleExamSubmissionsDialogueOpen}
            />
          </Box>
        )}
      </InfiniteScroll>
    )
  }

  return (
    <Box
      pad="small"
      fill
      align="left"
      justify="left"
      margin={{ top: 'small', right: 'small' }}
    >
      <Backdrop className={classes.backdrop} open={fetching}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {isExamCreatingDialogueOpen && returnExamCreatingDialogue()}
      {isExamSubmissionsDialogueOpen && returnExamSubmissionsViewDialogue()}

      <Box direction="row" justify="between">
        <Box width="small">
          <BatchSelector />
        </Box>

        <Box height="100px" pad="small">
          <GRButton
            alignSelf="end"
            label="+ Create New Exam"
            primary
            disabled={!selectedBatch}
            onClick={onCreateExamButtonPressed}
          />
        </Box>
      </Box>

      <Box margin={{ top: 'small' }} justify="left" align="left" width="600px">
        {renturnExamsList()}
      </Box>
    </Box>
  )
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '8%',
    padding: theme.spacing(2),
    marginBottom: '2%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  padding: {
    padding: 12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  input: {
    display: 'none',
  },
  tollbarButton: {
    marginLeft: 'auto',
  },
  tableRoot: {
    padding: 0,
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  tile: {
    padding: 0,
  },
}))

export default ExamsPage

import React, { useState, useContext, useEffect, useCallback } from 'react'
import firebase from '../firebase'
import { AuthContext } from './authContext'
import OrgData from '../../models/orgData'
import CourseProfile from '../../models/courseProfile'
import CourseBatch from '../../models/CourseBatch'
import { randomString } from '../../helpers'
import {
  courseData,
  courseStudentsList,
  appliedStudentsList,
} from '../fakeData'
// Models
import CourseLesson from '../../models/CourseLesson'
import Exam from '../../models/Exam'
import Student from '../../models/Student'

export const DBContext = React.createContext()

export const DBContextProvider = ({ children }) => {
  /**
   * To know whether we are verified or not
   */
  const isVeryfied = false

  const { currentUser } = useContext(AuthContext)
  const [fetching, setFetching] = useState(false)

  // Starter Data
  const [orgData, setOrgData] = useState(null)
  const [allCoursesData, setAllCoursesData] = useState(null)

  //courses
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [courseProfileData, setCourseProfileData] = useState(null)

  const [courseStudents, setCourseStudents] = useState([])

  const [courseBatches, setCourseBatches] = useState([])

  const [selectedBatch, setSelectedBatch] = useState(null)
  const [courseSubjects, setSubjects] = useState([])
  // STUDENTS
  const [pendingStudentList, setPendingStudentList] = useState([])
  // LESSONS
  const [lessonsList, setLessonsList] = useState([])

  //EXAMS
  const [examsList, setExamsList] = useState([])

  /**
   * DB REFS
   */
  //Firestore
  const db = firebase.firestore()
  //Storage
  const storage = firebase.storage()
  const COURSE_PROFILE_IMAGE_BUCKET = storage
    .ref()
    .child('COURSE_PROFILE_IMAGES')
  const COURSE_LESSON_VIDEOS_BUCKET = storage
    .ref()
    .child('COURSE_LESSON_VIDEOS')
  const COURSE_LESSON_PDF_BUCKET = storage.ref().child('COURSE_LESSON_PDFS')

  // collectin refs

  // USERS
  const USERS_DB = db.collection('USERS')
  //ORG
  const ORGS_DB = db.collection('ORGS')
  const ORG_COURSES_DB = db.collection('ORG_COURSES')
  //COURSES
  const COURSES_DB = db.collection('COURSES')
  const COURSE_PERMISSIONS_DB = db.collection('COURSE_PERMISSIONS')
  const COURSE_BATCHES_DB = db.collection('COURSE_BATCHES')
  const COURSE_STUDENTS_SENDERS_LIST_DB = db.collection(
    'COURSE_STUDENTS_SENDERS_LIST',
  )

  //STUDENTS
  const COURSE_STUDENTS_DB = db.collection('COURSE_STUDENTS')
  const COURSE_APPLIED_STUDENTS_DB = db.collection('COURSE_PENDING_STUDENTS')
  const STUDENT_COURSES_DB = db.collection('STUDENT_COURSES')
  // LESSONS
  const ONLINE_LESSONS_DB = db.collection('COURSE_LESSONS')
  const LESSON_COMMENTS_DB = db.collection('LESSON_COMMENTS')

  // EXAMS
  const COURSE_EXAMS_DB = db.collection('COURSE_EXAMS')
  const STUDENTS_ONLINE_EXAM_SUBMISSIONS_DB = db.collection(
    'STUDENTS_ONLINE_EXAMS_ANSWERS',
  )
  // To fetch and set the state data to make sure
  // all nessesery data is available at each page refresh
  const hydateState = useCallback(async () => {
    console.log('hydrating the state')
    setFetching(true)
    const orgsList = await fetchOrgData()
    if (orgsList && orgsList.length > 0) {
      const coursesList = await fetchOrgCourses(orgsList[0])
      console.log('coursesList', coursesList)
      if (coursesList && coursesList.length > 0) {
        // Fetch for Course Batches
        const batchesData = await fetcheCourseBatches(coursesList[0])
        setAllCoursesData([...coursesList])
        setOrgData(orgsList[0])
        setCourseProfileData(coursesList[0])
        setSelectedCourse(coursesList[0])

        if (batchesData && batchesData.length > 0) {
          setCourseBatches([...batchesData])
          // look at local storage for any stored batch data available

          // if available make it like selected batch
          // else make first one in the array as the default selected batch
        }
        setFetching(false)
      }
    } else {
      setFetching(false)
      setOrgData(null)
      setAllCoursesData(null)
      setSelectedCourse(null)
      setCourseProfileData(null)
      return
    }

    // if (allCoursesData && allCoursesData.length > 0) {
    //   console.log('all course data', allCoursesData)
    //   setSelectedCourse(allCoursesData[0])
    //   setCourseProfileData(allCoursesData[0])

    // }
    /**
     * TODO: PREPARE TO HANDLE MULTIPLE COURSES
     */
    //console.log('HYDRETED ALL COURSES LIST', allCoursesData)
  }, [])

  useEffect(() => {
    if (currentUser) {
      hydateState()
    }
  }, [currentUser])

  /**
   * AUTH FUNCTIONS
   */
  // Save Users Data into DB
  const saveUserDetails = (userData, uid) => {
    let ref = USERS_DB.doc(uid)
    return ref.set({
      ...userData,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
  }
  /**
   * ORG FUNCTIONS
   */

  // create organizatoin
  const createOrganization = async (payload) => {
    setFetching(true)

    //setOrgData(null)
    console.log(payload, 'from the db')

    try {
      await ORGS_DB.add({
        ...payload,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        admin: currentUser.uid,
        adminName: `${currentUser.displayName}`,
      })
      setFetching(false)

      return null
    } catch (error) {
      console.log(error)
      setFetching(false)

      return error
    }
  }
  const fetchOrgData = async () => {
    console.log(`${currentUser.uid}`)
    // fetch for organizations db if the user is admin of
    const orgQuery = ORGS_DB.where('admin', '==', `${currentUser.uid}`)
    try {
      const snapShot = await orgQuery.get()
      if (snapShot.empty) {
        console.log('No Organization is associated with the user')
        return null
      } else {
        console.log('user is working with some Org')

        // because we are using fetch, the results will come in arry only
        // even if there is one result
        const orgs = []
        snapShot.forEach(async (doc) => {
          if (doc) {
            const organizationData = new OrgData(doc.id, doc.data())
            if (!!organizationData) {
              console.log('got org data ', organizationData)
              orgs.push(organizationData)
            }
          }
        })
        return [...orgs]
      }
    } catch (error) {
      console.error(error.message)
      return null
    }
  }

  /**
   *
   * @param {Object} payload
   * @param {String} orgId
   * @returns
   */
  const updateOrgData = async (payload, orgId) => {
    setFetching(true)

    //setOrgData(null)
    //console.log(payload, 'from the db')

    try {
      await ORGS_DB.doc(orgId).update({
        ...payload,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      setFetching(false)

      return null
    } catch (error) {
      console.log(error)
      setFetching(false)

      return error
    }
  }

  // Fetches all course belongs to the org
  const fetchOrgCourses = async (org) => {
    //console.log('fetchProgramsAssociatedWithOrg')
    if (org) {
      try {
        //console.log('tyryig', orgData.id)
        //const orgProgramsCollection =

        let snapShot = await COURSES_DB.where('orgId', '==', org.id).get()

        //  await orgProgramsCollection
        if (snapShot.empty) {
          console.log('No courses are associted with this Org')
          return null
        } else {
          //console.log('programs present for this org')

          const coursesList = []
          snapShot.forEach((doc) => {
            //TODO: make it as a course data
            const courseData = new CourseProfile(doc.id, doc.data())
            console.log('courseData', courseData)
            coursesList.push(courseData)
          })
          console.log('COURSE LIST', coursesList)
          return [...coursesList]
        }
      } catch (error) {
        console.error('fetchProgramsAssociatedWithOrg', error)
        return null
      }
    } else {
      console.error('Org data is null', orgData)
      return null
    }
  }
  /**
   * COURSE FUNCTIONS
   */
  const updateStateValuesForCreatingNewCourse = () => {
    setCourseStudents([])
    setSelectedCourse(null)
    setCourseProfileData(null)
    setSubjects([])
    setCourseBatches([])
  }

  // For creating the course
  const createCourse = async (payload) => {
    if (orgData && payload) {
      setFetching(true)
      console.log(payload, 'from the db')

      // to crate uid for the course.
      const uid = randomString(21)
      const courseId = randomString(6)
      // as we are doing 3 writes simultaniously we are using
      // batch writes for the safty
      let batch = db.batch()
      const time = firebase.firestore.FieldValue.serverTimestamp()

      try {
        // FOR PERMISSIONS
        const coursesPermissonsRef = COURSE_PERMISSIONS_DB.doc(uid)
        batch.set(coursesPermissonsRef, { [currentUser.uid]: 'ADMIN' })

        // FOR ORG courses
        const orgCoursesRef = ORG_COURSES_DB.doc(orgData.id)
          .collection('OrgCourses')
          .doc(uid)
        batch.set(orgCoursesRef, { ...payload, createdAt: time })

        // FOR COURSE DATA
        const courseRef = COURSES_DB.doc(uid)
        batch.set(courseRef, {
          ...payload,
          courseId,
          createdAt: time,
          admin: currentUser.uid,
          orgId: orgData.id,

          orgData: {
            orgId: orgData.id,
            name: orgData.name,
            imageUrl: orgData.imageUrl,
          },
          createdBy: {
            uid: currentUser.uid,
            name: currentUser.displayName,
            email: currentUser.email,
          },
        })

        await batch.commit()
        //await fetchCourseProfileData(uid)
        // Fetch Course data and set the daate
        setFetching(false)

        return null
      } catch (error) {
        console.log(error.message)
        setFetching(false)

        return error.message
      }
    }
  }

  // const fetchCourseProfileData = async (courseId) => {
  //   if (courseId) {
  //     try {
  //       const courseRef = COURSES_DB.doc(courseId)
  //       const doc = await courseRef.get()
  //       if (doc.exists) {
  //         const courseData = new CourseProfile(doc.id, doc.data())
  //         setSelectedCourse(courseData)
  //         setCourseProfileData(courseData)
  //       } else {
  //         console.log('No such document!')

  //         setSelectedCourse(null)
  //         setCourseProfileData(null)
  //       }
  //     } catch (error) {
  //       setSelectedCourse(null)
  //       setCourseProfileData(null)
  //       console.log('Error getting document:', error)
  //     }
  //   }
  // }

  const updateCourseData = async (payload, id) => {
    if (payload && id) {
      setFetching(true)
      try {
        await COURSES_DB.doc(id).update({
          ...payload,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        await ORG_COURSES_DB.doc(orgData.id)
          .collection('OrgCourses')
          .doc(id)
          .update({
            ...payload,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          })
        setFetching(false)

        return null
      } catch (error) {
        console.log(error)
        setFetching(false)

        return error
      }
    }
  }

  // COURSE BATCHES
  const createNewcourseBatch = async (batchData) => {
    if (!selectedCourse) {
      return null
    }
    setFetching(true)

    //setOrgData(null)
    console.log(batchData, 'data')

    try {
      await COURSE_BATCHES_DB.doc(selectedCourse.id)
        .collection('batches')
        .add({
          ...batchData,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        })

      //Fetches for batches and  update state
      // batches list with new one
      const batchesData = await fetcheCourseBatches(selectedCourse)
      if (batchesData && batchesData.length > 0) {
        setCourseBatches([...batchesData])
      }
      setFetching(false)
      return
    } catch (error) {
      console.log(error)
      setFetching(false)
    }
  }
  const fetcheCourseBatches = async (course) => {
    if (!course) {
      return null
    }
    try {
      //console.log('tyryig', orgData.id)
      //const orgProgramsCollection =

      let snapShot = await COURSE_BATCHES_DB.doc(course.id)
        .collection('batches')
        .get()

      if (snapShot.empty) {
        console.log('No batches are there for the course')
        return null
      } else {
        //console.log('programs present for this org')

        const batchList = []
        snapShot.forEach((doc) => {
          //TODO: make it as a course data
          //const batchData = doc.data()
          //console.log('batchData', batchData)
          const batch = new CourseBatch(doc.id, doc.data())
          batchList.push(batch)
        })
        console.log('batch LIST', batchList)
        return [...batchList]
      }
    } catch (error) {
      console.error(error)
      return null
    }
  }

  // const updateBatchData = (batch) => {
  //   setSelectedBatch(batch)
  // }
  // const deleteBatch = (batchId) => {}

  const updateSelectedBatch = (batch) => {
    setSelectedBatch(batch)
  }

  /**
   * COURSE STUDENTS funcions
   */

  // This method is to fetch studtns data and store into the
  // state.
  const fetchCourseStudentsList = async () => {
    console.log('fetching course studetns list')
    if (!selectedCourse) {
      return 'No Course created yet'
    }
    // if (!selectedBatch) {
    //   return 'Please select a batch to send'
    // }
    const courseId = selectedCourse.id
    const studetnsQuery = COURSE_STUDENTS_DB.doc(courseId)
      .collection('students')
      .orderBy('createdAt', 'desc')
    //.where('batchId', '==', batch.batchId)

    setFetching(true)
    try {
      const querySnapShot = await studetnsQuery.get()
      let students = []
      if (!querySnapShot.empty) {
        querySnapShot.forEach((studentData) => {
          console.log(studentData.data())
          const student = new Student(studentData.id, studentData.data())

          students.push(student)
        })
      }
      console.log(`course students $${students}`)
      setCourseStudents([...students])
      setFetching(false)
    } catch (error) {
      console.error(error.message)
      setCourseStudents([])
      setFetching(false)
      return error.message
    }
  }

  // For fetching applyed students so that we can
  // approve them if they have paid the fee.
  const fetchAppliedStudentsList = async () => {
    if (!selectedCourse) {
      return 'No Course created yet'
    }

    const courseId = selectedCourse.id
    const appliedStudetnsQuery = COURSE_APPLIED_STUDENTS_DB.doc(
      courseId,
    ).collection('students')
    setFetching(true)
    try {
      const querySnapShot = await appliedStudetnsQuery.get()
      let students = []
      if (!querySnapShot.empty) {
        querySnapShot.forEach((studentData) => {
          //console.log(`studetn`, studentData.data())
          const student = new Student(studentData.id, studentData.data())
          //console.log(student)
          students.push(student)
        })
      }
      //console.log(`pending students $${students}`)
      setPendingStudentList([...students])
      setFetching(false)
    } catch (error) {
      console.error(error.message)
      setPendingStudentList([])
      setFetching(false)
      return error.message
    }
  }
  /**
   * 1) Add student to the course list along with the batch
   * 2) Add course data to the student profile
   * 3) creat a 'COURSE_STUDENTS_SENDERS_LIST' with student basic details
   * like studentId:{name, batchId} to easyly fetch studentlist while sending the
   * data to the students.
   * 4) Get student data from the database and add student to the course students
   * 5) Remove studetn from the pending student list.
   * @param {Object} student
   * @returns
   */
  const addStudentsToTheCourse = async (student, batchData) => {
    if (!selectedCourse) {
      return 'No Course created yet'
    }
    if (!student) {
      return 'Student data not available'
    }
    if (!batchData) {
      return 'Please select a batch to send'
    }
    const courseId = selectedCourse.id
    const studentId = student.id
    //return console.log('student id ', studentId)
    //console.log(courseId)
    // add student to the course ref
    const courseStudentsRef = COURSE_STUDENTS_DB.doc(courseId)
      .collection('students')
      .doc(studentId)
    // Needo to add course data
    const studentCoursesRef = STUDENT_COURSES_DB.doc(student.id)
      .collection('courses')
      .doc(courseId)
    // need to add student basic details
    const courseStudentsSendersListRef = COURSE_STUDENTS_SENDERS_LIST_DB.doc(
      courseId,
    )
      .collection('sendersList')
      .doc(studentId)
    // should remove from the db
    const appliedStudetnsQuery = COURSE_APPLIED_STUDENTS_DB.doc(courseId)
      .collection('students')
      .doc(studentId)

    let batch = db.batch()

    try {
      const studentData = student.returnStudentProfileObject()
      //console.log(`student data ${studentData}`)
      const courseData = selectedCourse.returnCourseProfleObject()
      //onsole.log('courseData', courseData)
      // adding student profile to course
      const batchInfo = batchData.getBatchObject()

      // return console.log(studentData, courseData, batchInfo)

      batch.set(courseStudentsRef, {
        ...studentData,
        ...batchInfo,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      // adding course data to the student profile
      batch.set(studentCoursesRef, {
        ...courseData,
        ...batchInfo,
        orgName: orgData.name,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      // adding basic details to the senders list
      batch.set(courseStudentsSendersListRef, {
        name: student.displayName,
        fatherPhoneNumber: student.fatherPhoneNumber ? student.fatherPhoneNumber : '',
        motherPhoneNumber: student.motherPhoneNumber ? student.motherPhoneNumber: '',
        courseId,
        ...batchInfo,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      })

      batch.delete(appliedStudetnsQuery)
      await batch.commit()
      // Get the student data and add that data to the first
      const studentDoc = await courseStudentsRef.get()
      const addedStudent = new Student(studentDoc.id, studentDoc.data())
      setFetching(false)
      setCourseStudents([addedStudent, ...courseStudents])
      // to re fetch all documents to show remining pening requests, we only remove one
      // student in the pending list and to show updated pending students list.
      const newList = pendingStudentList.filter(
        (item) => item.id !== student.id,
      )
      setPendingStudentList([...newList])
    } catch (error) {
      console.log(error.message)
      setFetching(false)

      return error.message
    }
  }

  const removeStudentFromTheAppliedList = async (student) => {
    if (!selectedCourse) {
      return 'No Course created yet'
    }
    const appliedStudetnsRef = COURSE_APPLIED_STUDENTS_DB.doc(selectedCourse.id)
      .collection('students')
      .doc(student.id)
    setFetching(true)
    try {
      await appliedStudetnsRef.delete()

      setFetching(false)
      const newList = pendingStudentList.filter(
        (item) => item.id !== student.id,
      )
      setPendingStudentList([...newList])
      // setAppliedStudents(
      // prevState => {
      //   prevState[0].id = `${Math.random()}`
      //   return [...newList]
      // })
    } catch (error) {
      console.log(error.message)
      setFetching(false)

      return error.message
    }
  }

  /**
   * COURSE EXAMS
   */

  const fetchExamsData = async () => {
    if (!selectedCourse) {
      return 'No Course created yet'
    }
    if (!selectedBatch) {
      return 'Please select a batch to send'
    }
    // //NOTE: check if the batch end datE is not passed
    // if(selectedBatch.batchEndingDate ){

    // }
    const courseId = selectedCourse.id
    const batchId = selectedBatch.batchId

    setFetching(true)
    setExamsList([])

    const examsQuery = COURSE_EXAMS_DB.doc(courseId)
      .collection('courseExams')
      .orderBy('createdAt', 'desc')
      .where('batchId', '==', batchId)
    try {
      const querySnapShot = await examsQuery.get()
      let exams = []
      if (!querySnapShot.empty) {
        querySnapShot.forEach((eachExamData) => {
          console.log(`Esam data`, eachExamData.data())
          const exam = new Exam(eachExamData.id, eachExamData.data())
          //console.log(exam)
          exams.push(exam)
        })
      }
      console.log(`Exam list $${exams}`)
      setExamsList([...exams])
      setFetching(false)
    } catch (error) {
      console.error(error.message)
      setExamsList([])
      setFetching(false)
      return error.message
    }
  }
  // const fetchMoreExams = (lastexam) => {}

  const createExam = async (examData) => {
    if (!selectedCourse) {
      return 'No Course created yet'
    }
    if (!selectedBatch) {
      return 'Please select a batch to send'
    }
    // //NOTE: check if the batch end datE is not passed
    // if(selectedBatch.batchEndingDate ){

    // }
    const courseId = selectedCourse.id
    const batchId = selectedBatch.batchId

    const courseExamsRef = COURSE_EXAMS_DB.doc(courseId).collection(
      'courseExams',
    )
    try {
      setFetching(true)
      const docRef = await courseExamsRef.add({
        ...examData,
        examSubmissionsCount: 0, // to track exam submissions
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdBy: {
          uid: currentUser.uid,
          name: currentUser.displayName,
          email: currentUser.email,
        },
       // courseData
       programType: 'COURSE',
       programTitle: selectedCourse.title,
       programId: selectedCourse.id,
       programBannerUrl: selectedCourse.bannerUrl,
       batchId,

        orgData: {
          orgId: orgData.id,
          name: orgData.name,
          imageUrl: orgData.imageUrl,
        },
        //collectionRef: schoolExamsRef.toString(),
       
      })

      /**
       * NOTE: to prevent to re-fetch all the exams after adding
       * each exam to the db, we get that exam from the cloud
       * add we add it at the first place
       */
      const addedExamDocument = await docRef.get()
      const addedExam = new Exam(addedExamDocument.id, addedExamDocument.data())
      //console.table(addedLessonDocument.data())
      // we added the newly crated lesson at the first possions
      // in the lessons array
      setExamsList([addedExam, ...examsList])
      //setError(null)
      setFetching(false)
      // Fetch and update School POSTS
      //fetchOnlineLessonVideosAndUpdateState()
    } catch (error) {
      console.log(error.message)
      setFetching(false)
      return error
    }
  }

  const updateExam = async (examData, examId) => {
    if (!examData || !examId) {
      return 'No Exam Selected '
    }
    if (!selectedCourse) {
      return 'No Course created yet'
    }

    // //NOTE: check if the batch end datE is not passed
    // if(selectedBatch.batchEndingDate ){

    // }
    const courseId = selectedCourse.id

    //const batchId = selectedBatch.batchId
    setFetching(true)
    const courseExamsRef = COURSE_EXAMS_DB.doc(courseId)
      .collection('courseExams')
      .doc(examId)

    try {
      await courseExamsRef.update({
        ...examData,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedBy: {
          uid: currentUser.uid,
          name: currentUser.displayName,
          email: currentUser.email,
        },
      })
      /**
       * NOTE: to prevent to re-fetch all the exams after adding
       * each exam to the db, we get that exam from the cloud
       * add we add it at the first place
       */
      const changedExamDocument = await courseExamsRef.get()
      const changedExam = new Exam(
        changedExamDocument.id,
        changedExamDocument.data(),
      )
      let index = 0
      for (var i = 0; i < examsList.length; i++) {
        if (examsList[i].id === examId) {
          index = i
        }
      }
      var list = [...examsList]
      list[index] = changedExam
      //console.table(addedLessonDocument.data())
      // we added the newly crated lesson at the first possions
      // in the lessons array
      setExamsList([...list])
      //setError(null)
      setFetching(false)
      // Fetch and update School POSTS
    } catch (error) {
      console.log(error.message)
      setFetching(false)
      return error.message
    }
  }

  const deleteExam = async (exam) => {
    if (!selectedCourse) {
      return 'No Course created yet'
    }
    if (!exam) {
      return 'Exam data not avaialble'
    }
    // //NOTE: check if the batch end datE is not passed
    // if(selectedBatch.batchEndingDate ){

    // }
    const courseId = selectedCourse.id

    setFetching(true)

    const courseExamsRef = COURSE_EXAMS_DB.doc(courseId)
      .collection('courseExams')
      .doc(exam.id)
    try {
      await courseExamsRef.delete()

      setFetching(false)
      // Fetch and update School POSTS
      const newList = examsList.filter((eachExam) => eachExam.id !== exam.id)
      setExamsList([...newList])
    } catch (error) {
      console.log(error.message)
      setFetching(false)
      return error.message
    }
  }

  // for fetching exam submission data
  const fetchExamSubmissions = async (examId) => {
    // const schoolExamSubmissonsCollectionRef = STUDENTS_ONLINE_EXAM_SUBMISSIONS_DB
    //   .doc(examId)
    //   .collection('examAnswerData');
    // try {
    //   const snapshot = await schoolExamSubmissonsCollectionRef.orderBy('examSubmittedAt', 'desc').get();
    //   const submissions = await snapshot.docs.map(doc => new ExamSubmission(doc.id, doc.data()));
    //   console.log('submissions', submissions);
    //   return submissions;
    // } catch (error) {
    //   console.log('fetchExamSubmissions Error:', error);
    //   return null;
    // }
  }

  /** COURSE LESSONS */
  const fetchCourseLessons = async () => {
    if (!selectedCourse) {
      return 'No Course created yet'
    }
    if (!selectedBatch) {
      return 'Please select a batch to send'
    }
    // //NOTE: check if the batch end datE is not passed
    // if(selectedBatch.batchEndingDate ){

    // }
    const courseId = selectedCourse.id
    const batchId = selectedBatch.batchId

    setFetching(true)
    setLessonsList([])
    const lessonQuery = ONLINE_LESSONS_DB.doc(courseId)
      .collection('onlineLessons')
      .orderBy('createdAt', 'desc')
      .where('batchId', '==', batchId)

    try {
      const querySnapShot = await lessonQuery.get()

      let lessons = []
      if (!querySnapShot.empty) {
        querySnapShot.forEach((eachLesionsItem) => {
          const lesson = new CourseLesson(
            eachLesionsItem.id,
            eachLesionsItem.data(),
          )
          //onsole.table(eachLesionsItem.data())
          lessons.push(lesson)
          //console.log(lesson)
        })
      }
      console.log(`Lesson list ${lessons}`)
      setLessonsList([...lessons])
      setFetching(false)
    } catch (error) {
      console.error(error.message)
      setLessonsList([])
      setFetching(false)
      return error.message
    }
  }
  /**
   * For lazyly fetching more lessons
   * in infinity scroll
   * @param {Object} lastLesson
   */
  const fetchMoreLessons = (lastLesson) => {}

  /**
   * For posting lesson data to the cloud
   * @param {Object} payload
   */
  const createCourseLesson = async (payload) => {
    if (!selectedCourse) {
      return 'No Course created yet'
    }
    if (!selectedBatch) {
      return 'Please select a batch to send'
    }
    // //NOTE: check if the batch end datE is not passed
    // if(selectedBatch.batchEndingDate ){

    // }
    const courseId = selectedCourse.id
    const batchId = selectedBatch.batchId

    const courseLessonsRef = ONLINE_LESSONS_DB.doc(courseId).collection(
      'onlineLessons',
    )

    try {
      const docRef = await courseLessonsRef.add({
        ...payload,
        likes: 0,
        comments: 0,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        courseId,
        createdBy: {
          uid: currentUser.uid,
          name: currentUser.displayName,
          email: currentUser.email,
        },
        // courseData
          programType: 'COURSE',
          programTitle: selectedCourse.title,
          programId: selectedCourse.id,
          programBannerUrl: selectedCourse.bannerUrl,
          batchId,
        
        

        orgData: {
          orgId: orgData.id,
          name: orgData.name,
          imageUrl: orgData.imageUrl,
        },
       
        
      })
      /**
       * NOTE: to prevent to re-fetch all the lessons after adding
       * each lesson to the db, we get that lesson from the cloud
       * add we add it at the first place
       */
      const addedLessonDocument = await docRef.get()
      const addedLesson = new CourseLesson(
        addedLessonDocument.id,
        addedLessonDocument.data(),
      )
      //console.table(addedLessonDocument.data())
      // we added the newly crated lesson at the first possions
      // in the lessons array
      setLessonsList([addedLesson, ...lessonsList])
      //setError(null)
      setFetching(false)
      // Fetch and update School POSTS
      //fetchOnlineLessonVideosAndUpdateState()
    } catch (error) {
      console.log(error.message)
      setFetching(false)
    }
  }
  const updateCourseLesson = async (payload, id) => {
    if (!payload || !id) {
      return 'No Exam Selected '
    }
    if (!selectedCourse) {
      return 'No Course created yet'
    }

    // //NOTE: check if the batch end datE is not passed
    // if(selectedBatch.batchEndingDate ){

    // }
    const courseId = selectedCourse.id

    setFetching(true)
    const courseLessonsRef = ONLINE_LESSONS_DB.doc(courseId)
      .collection('onlineLessons')
      .doc(id)

    try {
      await courseLessonsRef.update({
        ...payload,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedBy: {
          uid: currentUser.uid,
          name: currentUser.displayName,
          email: currentUser.email,
        },
      })

      /**
       * NOTE: to prevent to re-fetch all the lessons after adding
       * each exam to the db, we get that lessons from the cloud
       * add we replace it with the updated lesson data in the index
       * of the lessson data.
       */
      const changedLessonDocument = await courseLessonsRef.get()

      const changedLesson = new CourseLesson(
        changedLessonDocument.id,
        changedLessonDocument.data(),
      )
      // we are fining the index of the lesson that needs to be changed.
      let index = 0
      for (var i = 0; i < lessonsList.length; i++) {
        if (lessonsList[i].id === changedLesson.id) {
          index = i
        }
      }
      // replacing the indexed lesson with changed lesson data
      var list = [...lessonsList]
      list[index] = changedLesson
      setLessonsList([...list])
      setFetching(false)
    } catch (error) {}
  }
  const deleteCourseLesson = async (courseLessson) => {
    if (!selectedCourse) {
      return 'No Course created yet'
    }
    if (!courseLessson) {
      return 'Exam data not avaialble'
    }
    const courseId = selectedCourse.id
    setFetching(true)
    const courseLessonsRef = ONLINE_LESSONS_DB.doc(courseId)
      .collection('onlineLessons')
      .doc(courseLessson.id)

    try {
      await courseLessonsRef.delete()

      const newList = lessonsList.filter(
        (eachLesson) => eachLesson.id !== courseLessson.id,
      )
      setLessonsList([...newList])
      setFetching(false)
    } catch (error) {
      console.log(error.message)
      setFetching(false)
      return error.message
    }
  }

  return (
    <DBContext.Provider
      value={{
        fetching,
        orgData,
        allCoursesData,
        selectedCourse,
        courseProfileData,
        courseStudents,

        courseSubjects,

        //AUTH
        saveUserDetails,

        //ORG
        createOrganization,
        fetchOrgData,
        fetchOrgCourses,
        updateOrgData,

        // Course
        updateStateValuesForCreatingNewCourse,
        createCourse,
        updateCourseData,

        // Functions
        pendingStudentList,
        fetchCourseStudentsList,
        fetchAppliedStudentsList,
        addStudentsToTheCourse,
        removeStudentFromTheAppliedList,
        setCourseProfileData,

        // Batches
        courseBatches,
        selectedBatch,
        updateSelectedBatch,
        createNewcourseBatch,
        //updateBatchData,

        // Exams
        examsList,
        fetchExamsData,
        createExam,
        updateExam,
        deleteExam,

        // Lessons
        lessonsList,
        fetchCourseLessons,
        createCourseLesson,
        updateCourseLesson,
        deleteCourseLesson,

        // STORAGE REFS
        COURSE_PROFILE_IMAGE_BUCKET,
        COURSE_LESSON_VIDEOS_BUCKET,
        COURSE_LESSON_PDF_BUCKET,
      }}
    >
      {children}
    </DBContext.Provider>
  )
}

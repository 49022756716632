import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import CardActionArea from '@material-ui/core/CardActionArea'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'

const QuestionTile = ({
  questionData,
  openToEdit,
  deleteQuestion,
  type,
  key,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleEdit = (e) => {
    openToEdit(questionData)
    handleClose()
  }
  const handleDelete = (e) => {
    deleteQuestion(questionData.id)
    handleClose()
  }

  const returnWrittenExamTile = () => {
    return (
      <ListItem alignItems="flex-start" className={classes.root} key={key}>
        <Card className={classes.root}>
          <CardHeader
            action={
              <div>
                <IconButton aria-label="settings-exam" onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="simple-menu-table-exam"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => handleEdit(questionData)}>
                    Edit
                  </MenuItem>
                  <MenuItem
                    className={classes.error}
                    onClick={() => handleDelete(questionData)}
                  >
                    Delete
                  </MenuItem>
                </Menu>
              </div>
            }
            title={questionData.sectionIntro}
            subheader={`Maximum mark for each question in this  section ${questionData.marksForEachQuestion}`}
          />

          <CardContent>
            <CardActionArea className={classes.padding}>
              <Grid
                container
                spacing={1}
                justify="space-between"
                alignItems="center"
              >
                {questionData.questionsList.map((eachQuestion) => {
                  return (
                    <Grid item xs={12} sm={12}>
                      <Typography>
                        {`${parseInt(eachQuestion.qNumber) + 1}. ${
                          eachQuestion.qText
                        }`}{' '}
                      </Typography>
                    </Grid>
                  )
                })}
              </Grid>
            </CardActionArea>
          </CardContent>
          <Typography
            className={classes.leftPadding}
            variant="subtitle1"
          >{`Total marsk for Section: ${questionData.marksForEachQuestion}`}</Typography>
          <CardActions>
            {/* <Typography variant="subtitle1" color="textSecondary">{`Explenation: ${questionData.explentaions}`}</Typography> */}
          </CardActions>
        </Card>
      </ListItem>
    )
  }
  if (type === 'WRITTEN') {
    return returnWrittenExamTile()
  } else {
    return (
      <ListItem alignItems="flex-start" className={classes.root}>
        <Card className={classes.root}>
          <CardHeader
            action={
              <div>
                <IconButton aria-label="settings-exam" onClick={handleClick}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="simple-menu-table-exam"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => handleEdit(questionData)}>
                    Edit
                  </MenuItem>
                  <MenuItem
                    className={classes.error}
                    onClick={() => handleDelete(questionData)}
                  >
                    Delete
                  </MenuItem>
                </Menu>
              </div>
            }
            title={questionData.questionText}
            subheader={`Id: ${questionData.id}`}
          />

          <CardContent>
            <CardActionArea className={classes.padding}>
              <Grid
                container
                spacing={1}
                justify="space-between"
                alignItems="center"
              >
                {Object.keys(questionData.options).map((eachOptionKey) => {
                  return (
                    questionData.options[eachOptionKey] !== '' && (
                      <Grid item xs={6} sm={5}>
                        <Typography variant="subtitle1">
                          {`${eachOptionKey}. ${questionData.options[eachOptionKey]}`}
                        </Typography>
                      </Grid>
                    )
                  )
                })}
              </Grid>
            </CardActionArea>
          </CardContent>
          <Typography
            className={classes.leftPadding}
            variant="subtitle1"
          >{`Answer: ${questionData.answer}`}</Typography>
          <CardActions>
            <Typography
              variant="subtitle1"
              color="textSecondary"
            >{`Explenation: ${questionData.explentaion}`}</Typography>
          </CardActions>
        </Card>
      </ListItem>
    )
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    padding: 0,
  },
  padding: {
    paddingTop: theme.spacing(1),
  },
  error: {
    color: 'red',
  },
  leftPadding: {
    paddingLeft: theme.spacing(1),
  },
}))

export default QuestionTile

export default class CourseProfile {
  constructor(id, courseProfileData) {
    this.id = id
    this.courseId = courseProfileData.courseId
    this.title = courseProfileData.title
    this.shortDescription = courseProfileData.shortDescription
    this.bannerUrl = courseProfileData.bannerUrl
    this.description = courseProfileData.description

    this.whatWillYouLearn = courseProfileData.whatWillYouLearn
    this.courseIncludes = courseProfileData.courseIncludes
    this.price = courseProfileData.price
    this.subjects = courseProfileData.subjects ? courseProfileData.subjects : []
  }
  returnCourseProfleObject() {
    return {
      id: this.id,
      title: this.title,
      shortDescription: this.shortDescription,
      bannerUrl: this.bannerUrl,
      description: this.description,
      whatWillYouLearn: this.whatWillYouLearn,
      courseIncludes: this.courseIncludes,
      price: this.price,
      subjects: this.subjects,
    }
  }
}

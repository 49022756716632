import { useContext, useEffect, useState } from 'react'
import { Box, Grommet } from 'grommet'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const SubjectSelector = (props) => {
  const classes = useStyles()

  const { subjects, setSubjectData, selectedSubject } = props
  //const [subject, setSubject] = useState(null)

  // useEffect(() => {
  //   //setAllBatches([...batches])
  //   console.table(batches)
  // }, [])

  const updateSubjectData = ({ target }) => {
    //setSubject(target.value)
    setSubjectData(target.value)
  }
  return (
    <Box direction="row" width="100%" justify="between">
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label-for-subject">
          Select Subject
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label-subject"
          id="demo-simple-select-outlined"
          value={selectedSubject}
          onChange={updateSubjectData}
          label="Select Subject"
        >
          {subjects.map((eachSubject) => (
            <MenuItem value={eachSubject}>{eachSubject}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default SubjectSelector

import React, { useState, useEffect, useContext } from 'react'
import { Box } from 'grommet'

import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../controller/contexts/authContext'
import { DBContext } from '../../controller/contexts/dbContext'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import CssBaseline from '@material-ui/core/CssBaseline'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import ArrowForwordIcon from '@material-ui/icons/ArrowForwardOutlined'
import LinearProgress from '@material-ui/core/LinearProgress'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import TextField from '@material-ui/core/TextField'
import placeholder from '../../assets/image-placeholder.jpg'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import Divider from '@material-ui/core/Divider'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { CircularProgress } from '@material-ui/core'
//import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import CardActions from '@material-ui/core/CardActions'
import { flexbox } from '@material-ui/system'

const Welcome = () => {
  const { currentUser } = useContext(AuthContext)
  const history = useHistory()
  const classes = useStyles()
  const {
    orgData,
    allCoursesData,
    fetching,
    updateStateValuesForCreatingNewCourse,
    setSelectedProgramType,
    setIsNewProgramCreating,
    fetchOrgData,
  } = useContext(DBContext)
  const [loading, setLoading] = useState(false)

  //console.log('the history is', history);

  useEffect(() => {
    if (!fetching) {
      console.log('ALL', allCoursesData)
    }
    if (allCoursesData) {
      console.error(allCoursesData)
    }

    // Calling DBcontext mathod to fetch
    // schools related to the email id
    // for showing schools.
    //fetchSchoolDetailsAssociatedWithUserEmail()
    // !orgData && fetchOrgAssociatedData()
  }, [])

  //TODO: This function fetches the org data
  // if the org data present associated with the
  // user id then it will proceed fetching courses
  // realated to those courses.
  // if No org data is present, then it will return null.
  // const fetchOrgAssociatedData = async () => {
  //   console.log('fetchOrgAssociatedData')
  //   await fetchOrgData()
  //   console.log('Org data after initial fetch', orgData)
  // }

  // to move to the seclected course details
  const moveToSelectedCourseFromList = async (index) => {
    //TODO: UPDATE SELECTED COURSE.
    await history.push('/')
  }
  // Moves to organizations view
  const moveToOrgViewPage = () => {
    return history.push('/orgView')
  }

  // For word to the settings page
  const createNewCourse = async () => {
    // Make all course values empty
    updateStateValuesForCreatingNewCourse()
    // Move to settings page
    history.push('/settings')
  }

  const resendEmailVarification = () => {
    setLoading(true)
    //  verify the email id
    currentUser
      .sendEmailVerification()
      .then(function () {
        setLoading(false)
        alert('Verification Email is sent')
      })
      .catch((error) => {
        setLoading(false)
        console.log(error.message, error.errorcode)
        var errorCode = error.code
        var errorMessage = error.message
        console.log(errorMessage)
        errorMessage && alert(`${errorMessage} \n ${errorCode}`)
      })
  }

  const returnNoEmailVerificationMessage = () => {
    return (
      <Grid container spacing={0}>
        <Paper className={classes.paper}>
          <HighlightOffRoundedIcon
            fontSize="large"
            color="error"
            className={classes.icon}
          />
          <Typography
            variant="h5"
            noWrap={true}
            gutterBottom={true}
            color="inherit"
          >
            Email Verification Not Finished{' '}
          </Typography>
          <Typography variant="body1" gutterBottom={true} color="textPrimary">
            Please Check Your Email Address To Verify.{' '}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            If you dont recive verification email, click below to send
            veryifiction mail again.{' '}
          </Typography>
          <Button
            color="primary"
            onClick={resendEmailVarification}
            className={classes.verificationButton}
          >
            Resend Verification Email
          </Button>
        </Paper>
      </Grid>
    )
  }

  const renderOrganizationData = () => {
    if (fetching || loading) {
      return <CircularProgress />
    }
    return (
      <div>
        {!!orgData ? returnOrgDataAndCoursesList() : returnCreateOrgTile()}
      </div>
    )
  }

  const returnCreateOrgTile = () => {
    return (
      <Paper className={classes.paper}>
        <Typography variant="body1" color="textSecondary">
          No organization is associated with this account
        </Typography>

        <Box margin="medium">
          <Button
            variant="outlined"
            color="primary"
            onClick={moveToOrgViewPage}
          >
            + Create Organization
          </Button>
        </Box>
      </Paper>
    )
  }

  const returnOrgDataAndCoursesList = () => {
    //console.log('returnOrgsListAndSchoolCollegeCoursesList', orgData.imageUrl);
    return (
      <div>
        <Grid container direction="column" className={classes.fullWidth}>
          <Grid item xs={12} sm={12}>
            <Box mt={2} p={2}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cover}
                  image={orgData.imageUrl}
                  title={orgData.name}
                />
                <div className={classes.details}>
                  <CardContent className={classes.content}>
                    <Typography component="h5" variant="h5">
                      {orgData.name.toUpperCase()}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                      {orgData.orgType}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      onClick={moveToOrgViewPage}
                    >
                      Settings
                    </Button>
                  </CardActions>
                </div>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} className={classes.card}>
            <Box mt={1}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignContent="center"
                    p={1}
                    mb={1}
                  >
                    <Box width="100%">{returnCoursesTiles()}</Box>
                    <Box>
                      <Button color="primary" onClick={createNewCourse}>
                        + Add/Create New Course
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </div>
    )
  }

  const returnCoursesTiles = () => {
    if (fetching) {
      ;<Typography>Loading....</Typography>
    }

    if (!allCoursesData || allCoursesData.length < 1) {
      return <>{returnNoCoursesMessage()}</>
    }
    return (
      <div className={classes.tile}>
        <List dense={true}>
          {!!allCoursesData ? (
            allCoursesData.map((eachCourse) => (
              <Paper className={classes.listItem}>
                <ListItem key={`${Math.random()}`}>
                  <ListItemAvatar>
                    <Avatar src={eachCourse.bannerUrl} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={eachCourse.title}
                    //secondary={eachCourse.shortDescription}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="arrow-in"
                      onClick={() => handleClickOnCourse(eachCourse)}
                    >
                      <ArrowForwordIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </Paper>
            ))
          ) : (
            <Typography variant="h6" color="textSecondary">
              {' '}
              No Courses present
            </Typography>
          )}
        </List>
      </div>
    )
  }

  // When click on the school tile
  // it will add the
  const handleClickOnCourse = (courseData) => {
    //setSelectedCourse(courseData)
    history.push('/Settings')
  }

  const returnNoCoursesMessage = () => {
    return (
      <Typography paragraph variant="subtitle2" color="textSecondary">
        No Courses associated with this organization
      </Typography>
    )
  }

  /**Fetching the body component */
  const fetchBody = () => {
    return (
      <Box fill direction="column" justify="center" align="center" gap="small">
        <Box pad="small" margin="medium">
          <Typography
            variant="h5"
            component="h1"
            noWrap={true}
            gutterBottom={true}
            color="inherit"
          >
            {`Welcome, ${currentUser.displayName}`}
          </Typography>
        </Box>
        <Box flex="grow" pad="small">
          <Grid item>{renderOrganizationData()}</Grid>
        </Box>
      </Box>
    )
  }
  return (
    <>
      {(fetching || loading) && <LinearProgress />}

      <Container component="main" maxWidth="sm">
        <CssBaseline />
        {!!currentUser.emailVerified
          ? fetchBody()
          : returnNoEmailVerificationMessage()}
      </Container>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
    marginTop: theme.spacing(8),
    display: 'flex',
  },
  card: {
    display: 'flex',
    padding: 8,
  },
  cover: {
    width: 120,
    height: 150,
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(6),
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    width: '100%',
    height: '100%',
  },
  fullWidth: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
  },
  tile: {
    backgroundColor: theme.palette.background.paper,
  },

  select: {
    display: 'flex',
    paddingLeft: '10%',
  },
  list: {
    width: '100%',
    maxWidth: 400,
    padding: 8,
    backgroundColor: theme.palette.background.paper,
    margin: 16,
  },
  icon: {
    margin: theme.spacing(1),
  },
  verificationButton: {
    marginTop: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(2, 0, 1),
    backgroundColor: 'green',
  },
  orDiv: {
    marginTop: theme.spacing(3),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  addButton: {
    marginTop: 20,
  },
  listItem: {
    padding: 8,
    margin: 8,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },

  center: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
  },
}))

export default Welcome

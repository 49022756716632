import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { randomString } from '../../../helpers'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import clsx from 'clsx'
import InputAdornment from '@material-ui/core/InputAdornment'
//import cryptoRandomString from 'crypto-random-string'

const QuestionEntryEdit = ({
  open,
  closeDialogue,
  questionData,
  addQuestionToQuestionsList,
  update,
}) => {
  const classes = useStyles()
  const [questionText, setQuestionText] = useState('')
  const [answer, setAnswer] = useState('')
  const [explentaion, setExplentaion] = useState('')
  const [options, setOptions] = useState({
    A: '',
    B: '',
    C: '',
    D: '',
    E: '',
    F: '',
  })

  useEffect(() => {
    console.log('question data', questionData)
    if (!!questionData) {
      console.log(questionData.options)
      setQuestionText(questionData.questionText)
      setAnswer(questionData.answer)
      setExplentaion(questionData.explentaion)
      setOptions({ ...questionData.options })
    }
  }, [questionData])
  const handleOptionsChange = (name) => (event) => {
    setOptions({ ...options, [name]: event.target.value })
  }

  const handleChange = (name) => (event) => {
    if (name === 'qtext') {
      setQuestionText(event.target.value)
    }
    if (name === 'explentaion') {
      setExplentaion(event.target.value)
    }
  }

  const handleRadioChange = (event) => {
    setAnswer(event.target.value)
  }

  const addQuestion = () => {
    if (questionText.length < 5) {
      return alert(
        'Your question is too short, Question Should be more then 5 charectors',
      )
    }
    if (answer.length !== 1) {
      return alert('It seems you forgot to specify the answer')
    }
    if (
      options['A'] === '' ||
      options['B'] === '' ||
      options['C'] === '' ||
      options['D'] === ''
    ) {
      return alert('Please Provide  atleast 4 Options for the question')
    }
    if (!!questionData) {
      update({
        questionText,
        answer,
        explentaion,
        options,
        id: questionData.id,
      })
    } else {
      addQuestionToQuestionsList({
        questionText,
        answer,
        explentaion,
        options,
        id: randomString(8),
      })
    }
  }

  const close = () => {
    console.log('close')
    closeDialogue()
  }

  return (
    <>
      <Dialog
        onClose={close}
        aria-labelledby="customized-dialog-title-for-q-enty"
        open={open}
      >
        <DialogTitle
          id="customized-dialog-title-title-for-q-enty"
          onClose={close}
        >
          {!!questionData ? (
            <Typography variant="h6">Update Question</Typography>
          ) : (
            'Create Question'
          )}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3} className={classes.padding}>
            {/* Question */}
            <Grid item xs={12} sm={9}>
              <TextField
                required
                id="outlined-required-questiondi-$"
                label="Question"
                value={questionText}
                onChange={handleChange('qtext')}
                fullWidth
                multiline
              />
            </Grid>
            {/* Options Area */}
            <Grid item xs={12} sm={9}>
              <Grid container space={2}>
                <Grid item xs={12} sm={5}>
                  <TextField
                    id="standard-start-adornment-quistion-a"
                    className={clsx(classes.margin, classes.textField)}
                    onChange={handleOptionsChange('A')}
                    value={options['A']}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">A</InputAdornment>
                      ),
                    }}
                    fullWidth
                    multiline
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    id="standard-start-adornment-quistion-b"
                    className={clsx(classes.margin, classes.textField)}
                    onChange={handleOptionsChange('B')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">B</InputAdornment>
                      ),
                    }}
                    value={options['B']}
                    fullWidth
                    multiline
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    id="standard-start-adornment-quistion-c"
                    className={clsx(classes.margin, classes.textField)}
                    onChange={handleOptionsChange('C')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">C</InputAdornment>
                      ),
                    }}
                    value={options['C']}
                    fullWidth
                    multiline
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    id="standard-start-adornment-quistion-d"
                    className={clsx(classes.margin, classes.textField)}
                    onChange={handleOptionsChange('D')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">D</InputAdornment>
                      ),
                    }}
                    value={options['D']}
                    fullWidth
                    multiline
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    id="standard-start-adornment-quistion-e"
                    className={clsx(classes.margin, classes.textField)}
                    onChange={handleOptionsChange('E')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">E</InputAdornment>
                      ),
                    }}
                    value={options['E']}
                    fullWidth
                    multiline
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    id="standard-start-adornment-quistion-f"
                    className={clsx(classes.margin, classes.textField)}
                    onChange={handleOptionsChange('F')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">F</InputAdornment>
                      ),
                    }}
                    value={options['F']}
                    fullWidth
                    multiline
                  />
                </Grid>
              </Grid>
              {/* For Answer Selection */}
              <Grid item xs={12} sm={9}>
                <Grid container>
                  <Grid item xs={12} sm={9}>
                    <Typography> Answer: {answer}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="position"
                        name="answerSelect"
                        value={answer}
                        onChange={handleRadioChange}
                      >
                        <FormControlLabel
                          value="A"
                          disabled={options['A'].length < 1}
                          control={<Radio color="primary" />}
                          label="A"
                        />
                        <FormControlLabel
                          value="B"
                          disabled={options['B'].length < 1}
                          control={<Radio color="primary" />}
                          label="B"
                        />
                        <FormControlLabel
                          value="C"
                          disabled={options['C'].length < 1}
                          control={<Radio color="primary" />}
                          label="C"
                        />
                        <FormControlLabel
                          value="D"
                          disabled={options['D'].length < 1}
                          control={<Radio color="primary" />}
                          label="D"
                        />
                        <FormControlLabel
                          value="E"
                          disabled={options['E'].length < 1}
                          control={<Radio color="primary" />}
                          label="E"
                        />
                        <FormControlLabel
                          value="F"
                          disabled={options['F'].length < 1}
                          control={<Radio color="primary" />}
                          label="F"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={9}>
              {/** For Explenation */}
              <Typography> Explenation(optional)</Typography>
              <TextField
                id="outlined-multiline-static-exam"
                label="Explenation"
                multiline
                value={explentaion}
                onChange={handleChange('explentaion')}
                rows={4}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={addQuestion} color="primary">
            {!!questionData ? 'Update Question ' : '+ Add'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '8%',
    padding: theme.spacing(2),
    marginBottom: '2%',
  },
  padding: {
    padding: 12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  input: {
    display: 'none',
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    padding: theme.spacing(1),
  },
}))

export default QuestionEntryEdit

import {
  Box,
  Button,
  FileInput,
  FormField,
  TextArea,
  TextInput,
  Text,
  Table,
  Grommet,
  Image,
  List,
  Main,
  Avatar,
} from 'grommet'
import { Trash } from 'grommet-icons'
import { useState, useContext, useEffect } from 'react'
import { DBContext } from '../../controller/contexts/dbContext'
import firebase from '../../controller/firebase'
import { randomString } from '../../helpers'
import LinearProgress from '@material-ui/core/LinearProgress'

const SettingsPage = () => {
  const {
    orgData,
    fetching,
    createCourse,
    updateCourseData,
    courseProfileData,
    selectedCourse,
    fetchCourseData,
    COURSE_PROFILE_IMAGE_BUCKET,
  } = useContext(DBContext)
  const [title, setTitle] = useState('')
  const [shortDescription, setShortDescription] = useState('')
  const [whatWillYouLearn, setWhatWillYouLearn] = useState([])
  const [description, setDescription] = useState('')
  const [bannerImageData, setBannerImageData] = useState(null)
  const [bannerUrl, setBannerUrl] = useState(null)
  const [fileName, setFileName] = useState(null)
  const [courseIncludes, setCourseInclude] = useState([])
  const [subjects, setSubjects] = useState([])
  //TODO: In future
  // const [introVideoData, setIntroVideoData] = useState(null)
  // const [introVideoUrl, setIntroVideoUrl] = useState('')

  const [price, setPrice] = useState(0)
  //const [instructiors, setInstructors] = useState([])
  const [errors, setErrors] = useState([])
  //const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(null)
  const [isUploading, setIsUploading] = useState(false)

  useEffect(() => {
    //console.log('selectedCourse', selectedCourse)
    if (!fetching && selectedCourse) {
      //console.table('SELECT', selectedCourse)
      setTitle(selectedCourse.title)
      setShortDescription(selectedCourse.shortDescription)
      setWhatWillYouLearn([...selectedCourse.whatWillYouLearn])
      setDescription(selectedCourse.description)
      setBannerUrl(selectedCourse.bannerUrl)
      setCourseInclude([...selectedCourse.courseIncludes])
      setSubjects([...selectedCourse.subjects])
      setPrice(selectedCourse.price)
    }
  }, [fetching, selectedCourse])

  const saveOrUpdateCourseInfo = async () => {
    setErrors([])
    const errs = []
    if (title.length < 20) {
      errs.push('Title length should be more then 20 chars')
    }
    if (shortDescription.length < 100) {
      errs.push('Short Description length should be more then 100 chars')
    }
    if (whatWillYouLearn.length < 5) {
      errs.push('Please fill What will Students learn atleast 5 items')
    }
    if (description.length < 200) {
      errs.push('Description length should be more then 400 chars')
    }
    if (courseIncludes.length < 3) {
      errs.push('Course includes should not be less then 3 items')
    }
    if (!parseInt(price) || parseInt(price) < 499) {
      errs.push('Price should be atleast 499 rs.')
    }
    if (!bannerUrl && !bannerImageData) {
      errs.push('Please upload the course banner')
    }
    if (subjects && subjects.length < 1) {
      errs.push('Please Add Subjects')
    }

    // If any errors are present, we will return
    // showing errors
    if (errs.length > 0) {
      return setErrors([...errs])
    } else {
      // console.log(
      //   title,
      //   shortDescription,
      //   whatWillYouLearn,
      //   description,
      //   courseIncludes,
      //   price,
      //   bannerUrl,
      // )

      // If the banner data is present that means we changed
      // or uploaded a new image so first we upload the iamge and
      // later we save the data
      if (bannerImageData) {
        const imageStoringRef = COURSE_PROFILE_IMAGE_BUCKET.child(fileName)
        //start progress indicator
        setIsUploading(true)
        var uploadTask = imageStoringRef.put(bannerImageData)

        // 1) Upload image and get URL
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          function (snapshot) {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            //console.log('Upload is ' + progress + '% done');
            setProgress(Math.round(progress))
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused')
                break
              case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running')
                break
              default:
                break
            }
          },
          function (error) {
            console.log('Error while uploading file to cloud storage', error)
            setIsUploading(false)
            setProgress(null)
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                console.log("User doesn't have permission to access the object")
                break

              case 'storage/canceled':
                // User canceled the upload
                alert('User canceled the upload')
                break

              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                console.log('ERROR: storage/unknown')
                break
              default:
                break
            }
          },
          function () {
            setIsUploading(false)
            setProgress(null)
            // url = await lessonStorageRef.getDownloadURL();
            // console.log('video url', url);
            // setIsUploading(false)
            // Upload completed successfully, now we can get the download URL
            imageStoringRef.getDownloadURL().then(async function (downloadURL) {
              console.log('File available at', downloadURL)
              const payload = {
                title,
                shortDescription,
                description,
                whatWillYouLearn,
                courseIncludes,
                price,
                subjects,
                bannerUrl: downloadURL,
              }

              if (selectedCourse) {
                // we are updating the data
                let error = await updateCourseData(payload, selectedCourse.id)
                if (error) {
                  setErrors([error])
                }
              } else {
                // create profile data

                let error = await createCourse(payload)

                if (error) {
                  setErrors([error])
                }
              }
            })
          },
        )
      } else {
        // We are not updating our iamge.
        const payload = {
          title,
          shortDescription,
          description,
          whatWillYouLearn,
          courseIncludes,
          price,
          bannerUrl,
          subjects,
        }
        console.log('the paylod is ', payload)
        // If the profile data is present alrady, that means we are
        // updating the data otherwise we are crating new course
        if (courseProfileData) {
          // we are updating the data
          let error = await updateCourseData(payload, courseProfileData.id)
          if (error) {
            setErrors([error])
          }
        } else {
          // create profile data
          let error = await createCourse(payload)
          if (error) {
            setErrors([error])
          }
        }
      }
    }
  }

  const getFileDataFromTheFileObject = async (file) => {
    // If the file size is more then 100 kb,
    // we just return without doing anything

    if (file.size > 100000) {
      console.error('File size too high')
      return
    }
    console.log('getFileDataFromTheFileObject called')
    var reader = new FileReader()

    if (reader) {
      //console.log('reader is present')
      reader.onload = (e) => {
        let buffer = e.target.result
        // We have to convert the buffer to a blob:
        let imageBlob = new Blob([new Uint8Array(buffer)], {
          type: `${file.type}`,
        })
        const random = randomString(4)
        setFileName(`${random}${file.name}`)
        setBannerImageData(imageBlob)
      }

      reader.readAsArrayBuffer(file)
    }
  }

  const imageUploadComponent = () => {
    return (
      <Grommet theme={customTheme}>
        <Box align="center" justify="start" pad="small" flex>
          <Box width="large" background="yellow" flex>
            <FileInput
              renderFile={(file) => {
                if (file.size > 100000) {
                  return (
                    <Box size="small">
                      <Text color="red">
                        {'Error: File size must be < 100 KB'}
                      </Text>
                    </Box>
                  )
                }
                return (
                  <Box
                    width="250px"
                    height="200px"
                    pad="small"
                    justify="left"
                    align="left"
                  >
                    <img
                      style={{ width: '60px', height: '100px' }}
                      src={
                        bannerImageData && URL.createObjectURL(bannerImageData)
                      }
                      alt={file.name}
                    />

                    {/* <Text weight="bold">{file.name}</Text>
                    <Text color="text-weak">{file.size} bytes</Text> */}
                  </Box>
                )
              }}
              onChange={async (event) => {
                // First make sure the previous data in the state
                // is null
                setBannerImageData(null)
                // get file from the event
                const fileList = event.target.files
                const file = fileList[0]

                getFileDataFromTheFileObject(file)
              }}
            />
          </Box>
        </Box>
      </Grommet>
    )
  }
  return (
    <Main>
      <Box pad="small">
        {(fetching || isUploading) && <LinearProgress />}
        <Box direction="row" justify="between">
          <Text size="xlarge">Add/ Edit Course Data</Text>

          <Box margin={{ right: 'large' }}>
            <Button
              label={`${selectedCourse ? 'Update' : 'Create Course'}`}
              primary
              onClick={saveOrUpdateCourseInfo}
            ></Button>
          </Box>
        </Box>

        <Box
          pad={{ left: 'small', top: 'small' }}
          // background="status-error"
          round="small"
        >
          {errors.map((eachError) => (
            <Text color="status-error">{`* ${eachError}`}</Text>
          ))}
        </Box>
        {/* Page Main  */}
        <Box fill margin={{ top: 'medium' }} overflow={{ vertical: 'scroll' }}>
          <Box width="large">
            <Table>
              <Box pad={{ left: 'small', top: 'medium' }}>
                <Text> Course Banner</Text>
                {bannerUrl && (
                  <Box
                    alignSelf="center"
                    pad="small"
                    height="small"
                    width="medium"
                    margin="small"
                  >
                    <Image src={bannerUrl} fit="cover" />
                  </Box>
                )}
              </Box>

              {imageUploadComponent()}

              <FormField label="Course Title" required={true}>
                <TextInput
                  placeholder="Type here"
                  value={title}
                  onChange={(event) => setTitle(event.target.value)}
                />
              </FormField>

              <FormField label="Short Description" required={true}>
                <TextArea
                  resize={false}
                  placeholder="Short Intoro <20 words"
                  value={shortDescription}
                  onChange={(event) => setShortDescription(event.target.value)}
                />
              </FormField>

              <FormField
                label="Cost of the cours per each student"
                required={true}
              >
                <TextInput
                  placeholder="Eg: 5000"
                  value={price}
                  onChange={(event) => setPrice(event.target.value)}
                />
              </FormField>

              <Box pad={{ left: 'small', top: 'medium' }}>
                <Text> What will Students learn from this course</Text>
              </Box>
              <Box pad={{ top: 'small' }}>
                {
                  /**
                   * This code is for adding the new text field dinamically
                   * to enter new line and it will automatically
                   * save to the specific index in the state when the user typed.
                   */
                  whatWillYouLearn.map((item, index) => (
                    <Box key={index}>
                      <FormField key={index}>
                        <TextInput
                          key={index}
                          placeholder="type here"
                          value={item}
                          onChange={(e) => {
                            const arr = [...whatWillYouLearn]
                            arr[index] = e.target.value

                            // console.log(arr)
                            setWhatWillYouLearn([...arr])
                            // console.error(whatWillYouLearn)
                          }}
                        />
                      </FormField>
                    </Box>
                  ))
                }
              </Box>
              <Button
                label="+ ADD"
                size="small"
                alignSelf="end"
                onClick={() => setWhatWillYouLearn([...whatWillYouLearn, ''])}
              ></Button>

              <Box pad={{ left: 'small', top: 'medium' }}>
                <Text> This course includes</Text>
              </Box>
              <Box pad={{ top: 'small' }}>
                {
                  /**
                   * This code is for adding the new text field dinamically
                   * to enter new line and it will automatically
                   * save to the specific index in the state when the user typed.
                   */
                  courseIncludes.map((item, index) => (
                    <FormField key={index}>
                      <TextInput
                        key={index}
                        placeholder="type here"
                        value={item}
                        onChange={(e) => {
                          //console.error(e.target.value, index)
                          const arr = [...courseIncludes]
                          arr[index] = e.target.value

                          //console.log(arr)
                          setCourseInclude([...arr])
                        }}
                      />
                    </FormField>
                  ))
                }
              </Box>

              <Button
                label="+ ADD"
                size="small"
                alignSelf="end"
                onClick={() => setCourseInclude([...courseIncludes, ''])}
              ></Button>

              <Box pad={{ left: 'small', top: 'medium' }}>
                <Text> Subjects/Topics </Text>
              </Box>
              <Box pad={{ top: 'small' }}>
                {
                  /**
                   * This code is for adding the new text field dinamically
                   * to enter new line and it will automatically
                   * save to the specific index in the state when the user typed.
                   */
                  subjects &&
                    subjects.map((item, index) => (
                      <Box key={index}>
                        <FormField key={index}>
                          <TextInput
                            key={index}
                            placeholder="type here"
                            value={item}
                            onChange={(e) => {
                              const arr = [...subjects]
                              arr[index] = e.target.value

                              // console.log(arr)
                              setSubjects([...arr])
                              // console.error(whatWillYouLearn)
                            }}
                          />
                        </FormField>
                      </Box>
                    ))
                }
              </Box>
              <Button
                label="+ ADD"
                size="small"
                alignSelf="end"
                onClick={() => setSubjects([...subjects, ''])}
              ></Button>

              <Box pad={{ top: 'medium' }}>
                <FormField label="Description" required={true}>
                  <TextArea
                    plain={false}
                    resize={false}
                    placeholder="Long Description  >100 words"
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                  />
                </FormField>
              </Box>
            </Table>
          </Box>
        </Box>
      </Box>
    </Main>
  )
}

const customTheme = {
  fileInput: {
    background: '#f2f2f2',
    border: { size: 'medium' },
    pad: { horizontal: 'large', vertical: 'medium' },
    round: 'small',
    label: {
      size: 'large',
    },
    icons: {
      remove: Trash,
    },
    dragOver: {
      border: { color: 'focus' },
    },
    hover: {
      border: { color: 'control' },
      extend: `letterSpacing: '0.1em'`,
    },
  },
}

export default SettingsPage

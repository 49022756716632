import { Box, Spinner } from 'grommet'
import React, { useEffect, useState } from 'react'
import firebase from '../firebase'
import Loding from '../../views/components/loading'

export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
  const [pending, setPending] = useState(true)

  const auth = firebase.auth()
  //console.log('auth context renderd')
  // Listion to the auth status and if the auth is changed
  // this will signout the user
  useEffect(() => {
    //setLoading(true)
    auth.onAuthStateChanged((user) => {
      if (user) {
        console.log(user)
        if (!currentUser) {
          setCurrentUser(user)
          setPending(false)
        }
      } else {
        setCurrentUser(null)
        setPending(false)
      }
    })
  }, [auth, currentUser])
  // const setTokens = (data) => {
  //   localStorage.setItem('tokens', JSON.stringify(data))
  //   setAuthTokens(data)
  // }

  // *** Auth API ***
  const doCreateUserWithEmailAndPassword = (email, password) =>
    auth.createUserWithEmailAndPassword(email, password)

  const doSignInWithEmailAndPassword = (email, password) =>
    auth.signInWithEmailAndPassword(email, password)

  const doPasswordReset = (email) => auth.sendPasswordResetEmail(email)

  const doPasswordUpdate = (password) =>
    auth.currentUser.updatePassword(password)

  const doSignOut = () => auth.signOut()

  if (pending) {
    return <Loding />
  }
  return (
    <AuthContext.Provider
      value={{
        currentUser,
        pending,
        auth,
        doSignOut,
        doCreateUserWithEmailAndPassword,
        doSignInWithEmailAndPassword,
        doPasswordReset,
        doPasswordUpdate,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

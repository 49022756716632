export default class OrgData {
  constructor(id, data) {
    //console.log('establishedAt', data.establishedAt)
    this.id = id
    this.admin = data.admin
    this.adminName = data.adminName
    this.createdAt = data.createdAt
    this.name = data.name
    this.subHeading = data.subHeading
    this.descrepiton = data.descrepiton
    this.address = data.address
    this.city = data.city
    this.town = data.town
    this.state = data.state
    this.country = data.country
    this.pincode = data.pincode
    this.phoneNumber1 = data.phoneNumber1
    this.phoneNumber2 = data.phoneNumber2
    this.introVideoUrl = data.introVideoUrl
    this.imageUrl = data.imageUrl
    this.schoolCollegeProgrms = data.schoolCollegeProgrms
    this.courses = data.courses
    this.orgType = data.orgType
    this.emailId = data.emailId
    this.establishedAt = data.establishedAt
  }
}

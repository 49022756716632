import React, { useContext } from 'react'
import { DBContext } from '../../controller/contexts/dbContext'
import { AuthContext } from '../../controller/contexts/authContext'
import {
  Avatar,
  Button,
  Box,
  Nav,
  Stack,
  Text,
  Sidebar,
  grommet,
  RoutedButton as GrommetRoutedButton,
  Grommet,
} from 'grommet'

import {
  AppsRounded,
  Split,
  StatusInfoSmall,
  Edit,
  SettingsOption,
  Group,
  Logout,
} from 'grommet-icons'

const SidebarHeader = () => {
  const { selectedCourse } = useContext(DBContext)

  return (
    <Box
      align="center"
      gap="small"
      direction="row"
      margin={{ bottom: 'small' }}
      width="200px"
    >
      <Stack alignSelf="start" align="center" anchor="top-right">
        <Avatar src={selectedCourse ? selectedCourse.bannerUrl : ''} />
        <Box pad="xsmall" background="accent-1" round responsive={true} />
      </Stack>
      <Text truncate={true}>
        {selectedCourse ? `${selectedCourse.title}` : 'Create course'}
      </Text>
    </Box>
  )
}

const SidebarButtonWithRoute = ({ icon, label, path, ...rest }) => (
  <Box pad="none">
    <GrommetRoutedButton
      gap="small"
      alignSelf="start"
      plain
      icon={icon}
      label={label}
      path={path}
      {...rest}
    />
  </Box>
)
const SidebarButton = ({ icon, label, ...rest }) => (
  <Box pad="none">
    <Button
      gap="small"
      alignSelf="start"
      plain
      icon={icon}
      label={label}
      {...rest}
    />
  </Box>
)

const SidebarFooter = () => {
  const { doSignOut } = useContext(AuthContext)
  return (
    <Nav>
      {/* <SidebarButton icon={<Help />} label="Support" /> */}
      <SidebarButton icon={<Logout />} label="Logout" onClick={doSignOut} />
    </Nav>
  )
}

const MainNavigation = () => (
  <Nav gap="large" responsive={true}>
    <SidebarButtonWithRoute icon={<StatusInfoSmall />} label="Home" path="/" />
    <SidebarButtonWithRoute
      icon={<Group />}
      label="Students"
      path="/students"
    />
    <SidebarButtonWithRoute
      icon={<AppsRounded />}
      label="Batches"
      path="/batches"
    />
    {/* <SidebarButtonWithRoute icon={<Clock />} label="Time Table" path='/time-table'/> */}
    <SidebarButtonWithRoute icon={<Split />} label="Teach" path="/tech" />
    <SidebarButtonWithRoute icon={<Edit />} label="Exams" path="/exams" />
    <SidebarButtonWithRoute
      icon={<SettingsOption />}
      label="Settings"
      path="/settings"
    />
  </Nav>
)

const SideBarComponent = () => (
  <Sidebar
    responsive={false}
    background="neutral-3"
    header={<SidebarHeader />}
    footer={<SidebarFooter />}
    pad={{ left: 'medium', right: 'large', vertical: 'medium' }}
  >
    <MainNavigation />
  </Sidebar>
)

export default SideBarComponent

import { Box, Text } from 'grommet'

const HomePage = () => {
  return (
    <Box fill>
      <Box justify="center" align="center" flex>
        <Text>No activity present </Text>
      </Box>
    </Box>
  )
}

export default HomePage

import React, { useEffect, useContext, useState } from 'react'
import { DBContext } from '../../controller/contexts/dbContext'
import InfoTile from '../components/infoTile'
import StudentListViewComponent from '../components/studentsListViewComponent'
import PendingStudentsListViewComponent from '../components/pendingStudentsListViewComponent'
import RichTabTitle from '../components/richTabTitle'
import StudentDataAlertDialogue from '../components/studentDetailsDialogue'
import { Box, Main, Tab, Tabs } from 'grommet'
import {
  CircleInformation,
  StatusGood,
  Currency,
  Group,
  UserAdd,
} from 'grommet-icons'
import { courseData } from '../../controller/fakeData'
//import { courseStudentsList } from '../../controller/fakeData'
const StudentsPage = () => {
  const {
    courseStudents,
    pendingStudentList,
    selectedCourse,
    fetchCourseStudentsList,
  } = useContext(DBContext)

  const [showStudentInfo, setShowStudentInfo] = useState(false)
  const [showPendingStudentRequest, setShowPendingStudentRequest] = useState(
    false,
  )
  const [selectedStudent, setSelectedStudent] = useState(null)

  // This will fetch and load basic student
  // data from the context while the bigening
  useEffect(() => {
    if (selectedCourse) {
      fetchCourseStudentsList()
    }
  }, [])

  // For showing and closing student alert dialogue
  const showStudentAleart = (student) => {
    if (student != null) {
      setSelectedStudent(student)
      setShowStudentInfo(true)
    }
  }
  const closeStudentAleart = () => {
    setSelectedStudent(null)
    setShowStudentInfo(false)
  }
  // For showing and closing student alert dialogue
  const showPendingStudentAleart = (student) => {
    if (student != null) {
      setSelectedStudent(student)
      setShowPendingStudentRequest(true)
    }
  }
  const closePendingStudentAleart = () => {
    setSelectedStudent(null)
    setShowPendingStudentRequest(false)
  }

  // For loading course students and
  // applied studetns.
  const loadStudentsData = async () => {
    // await fetchCourseStudentsList()
    // console.log('in loadStudentsData', courseStudents)
    // await fetchAppliedStudentsList()
  }

  const renderTopInfoRow = () => {
    return (
      <Box
        direction="row"
        height="100%"
        gap="medium"
        justify="between"
        pad="none"
      >
        <Box
          pad="none"
          background="accent-3"
          height="100%"
          width="medium"
          round="small"
          justify="center"
          align="center"
        >
          <InfoTile
            icon={<Group size="40px" />}
            number={courseStudents ? `${courseStudents.length}` : '0'}
            title="Students"
          />
        </Box>
        <Box
          pad="none"
          background="accent-2"
          height="100%"
          width="medium"
          round="small"
          justify="center"
          align="center"
        >
          <InfoTile
            icon={<UserAdd size="40px" />}
            number={pendingStudentList ? `${pendingStudentList.length}` : '0'}
            title="Pending"
          />
        </Box>
        <Box
          pad="none"
          background="accent-1"
          height="100%"
          width="medium"
          round="small"
          justify="center"
          align="center"
        >
          <InfoTile
            icon={<Currency size="40px" />}
            number={`\u20B9  ${courseStudents.length * courseData.coursePrice}`}
            title="Estimated Earnings"
          />
        </Box>
      </Box>
    )
  }

  const renderStudentsTabView = () => {
    // console.log('Students list from renderStudentsTabView', studentsList)
    return (
      <Box pad="none" margin={{ top: '20px' }}>
        <Tabs>
          <Tab
            title={
              <RichTabTitle
                icon={<StatusGood color="neutral-1" />}
                label="Students"
              />
            }
          >
            <StudentListViewComponent
              students={courseStudents}
              showStudentAlert={showStudentAleart}
              closeStudentAleart={closeStudentAleart}
            />
          </Tab>

          <Tab
            title={
              <RichTabTitle
                icon={<CircleInformation color="status-warning" />}
                label="Pending"
              />
            }
          >
            <PendingStudentsListViewComponent
              students={pendingStudentList}
              showStudentAlert={showPendingStudentAleart}
              closeStudentAleart={closePendingStudentAleart}
            />
          </Tab>
        </Tabs>
      </Box>
    )
  }

  return (
    <Box pad="small" align="left" justify="left">
      {showStudentInfo && selectedStudent && (
        <StudentDataAlertDialogue
          closeDialogue={closeStudentAleart}
          studentData={selectedStudent}
          isPendingRequest={false}
        />
      )}
      {selectedStudent && showPendingStudentRequest && (
        <StudentDataAlertDialogue
          closeDialogue={closePendingStudentAleart}
          studentData={selectedStudent}
          isPendingRequest={true}
        />
      )}
      <Box
        direction="column"
        pad="small"
        //background="pink"

        gap="medium"
      >
        <Box pad="xsmall" height="100px">
          {renderTopInfoRow()}
        </Box>
        <Box pad="xsmall">{renderStudentsTabView()}</Box>
      </Box>
    </Box>
  )
}

export default StudentsPage

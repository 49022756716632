import React, { useState, useEffect, useContext } from 'react'
import { DBContext } from '../../../controller/contexts/dbContext'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import ReactPlayer from 'react-player'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import RefreshIcon from '@material-ui/icons/Refresh'
import CardActionArea from '@material-ui/core/CardActionArea'
import Chip from '@material-ui/core/Chip'
import CommentTile from './commentTile'
import { CircularProgress, Divider } from '@material-ui/core'
import List from '@material-ui/core/List'
import Button from '@material-ui/core/Button'
import { Box, Text } from 'grommet'

var moment = require('moment')

const VideoCommentsDialogue = (props) => {
  const classes = useStyles()
  const { lessonData } = props
  const [commentsData, , setCommentsData] = useState([])
  const { fetching, postAnswerToQuestion } = useContext(DBContext)

  // lodes the live data from comments related to the
  // video component
  // this method will  call the dbContext function to fetch live comments
  // and detauch listeners when the componet will unmount
  useEffect(() => {
    try {
      console.log('lesson commentd', lessonData)
      getLessonComments()
      //console.log('commentsData', commentsData)
    } catch (error) {
      console.log(error)
    }
  }, [])

  const getLessonComments = async () => {}

  const returnLessonCard = () => {
    return (
      <ListItem alignItems="flex-start">
        <Card className={classes.videoTitle}>
          <CardHeader
            title={lessonData.lessonTitle}
            subheader={
              lessonData.createdAt &&
              moment(lessonData.createdAt.toDate()).format('MMMM Do YYYY')
            }
          />
          <ReactPlayer url={lessonData.lessonUrl} controls />
          <CardContent>
            <Box margin={{ vertical: '8px' }}>
              <Typography variant="body2" color="textSecondary" component="p">
                {lessonData.lessonDescription}
              </Typography>
            </Box>

            <CardActionArea className={classes.padding}>
              <Chip size="small" label={lessonData.selectedSubject} />
            </CardActionArea>
          </CardContent>
        </Card>
      </ListItem>
    )
  }
  const returnCommentData = () => {
    if (fetching) {
      return <CircularProgress />
    }
    if (commentsData.length < 1) {
      return <Typography> No Comments Yet </Typography>
    } else {
      return (
        <List className={classes.root}>
          {commentsData.map((eachComment) => (
            <CommentTile
              key={`${Math.random()}+COMMENT`}
              commentData={eachComment}
              lessonData={lessonData}
            />
          ))}
        </List>
      )
    }
  }
  return (
    <Box justify="center" align="center">
      <div className={classes.root}>
        <Grid container md spacing={4} direction="column" justify="center">
          <Grid item xs={12} sm={9}>
            {returnLessonCard()}
            <Divider />
          </Grid>
          <Grid item xs={12} sm={9}>
            <Button
              variant="contained"
              color="default"
              className={classes.button}
              startIcon={<RefreshIcon />}
              onClick={() => getLessonComments(lessonData.id)}
            >
              Refresh
            </Button>
          </Grid>
          <Grid item xs={12} sm={9}>
            {returnCommentData()}
          </Grid>
        </Grid>
      </div>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  videoTitle: {
    width: '100%',
    height: '80%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  padding: {
    paddingTop: theme.spacing(1),
  },
  error: {
    color: 'red',
  },
}))

export default VideoCommentsDialogue
